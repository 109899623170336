import apiServiceFetchers from "../../services/apiServiceFetchers";
import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
} from "../ActionTypes";

// Action Creators
export const fetchNotifications = (token) => async (dispatch) => {
  dispatch({ type: FETCH_NOTIFICATIONS_REQUEST });

  try {
    const response = await apiServiceFetchers.getNotifications(token);
    const notifications = Array.isArray(response.notifications)
      ? response.notifications
      : [];

    const sortedNotifications = notifications.sort((a, b) =>
      a.isRead === b.isRead
        ? new Date(b.updatedAt) - new Date(a.updatedAt)
        : a.isRead - b.isRead
    );

    dispatch({
      type: FETCH_NOTIFICATIONS_SUCCESS,
      payload: sortedNotifications,
    });
  } catch (error) {
    dispatch({ type: FETCH_NOTIFICATIONS_FAILURE, error: error.message });
  }
};
