import { useState, useContext, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Divider,
  Snackbar,
  Alert,
  Modal,
  Box,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceActions from "../../services/apiServiceActions";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import LoadingData from "../../components/Loaders/Loading";
import { useTheme } from "@mui/material/styles";
// import { Add } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";

export default function Wallets() {
  const { currentUser } = useContext(AuthContext);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);

  const [newCoin, setNewCoin] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openModal, setOpenModal] = useState(false); // Modal state
  const theme = useTheme();

  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await apiServiceFetchers.getOwnerWallets(
          currentUser.token
        );
        if (response) {
          setWallets(
            response.map((wallet) => ({
              coin: wallet.coinId,
              address: wallet.address,
            }))
          );
        }
      } catch (error) {
        throw error
      } finally {
        setLoading(false);
      }
    };
    fetchWallets();
  }, [currentUser.token]);

  const handleRegisterCoin = async () => {
    if (!newCoin || !newAddress || !privateKey) {
      alert("Please fill in all fields.");
      return;
    }
    try {
      await apiServiceActions.createOwnerWallet(
        newCoin,
        newAddress,
        privateKey,
        currentUser.token
      );
      setWallets([...wallets, { coin: newCoin, address: newAddress }]);
      setNewCoin("");
      setNewAddress("");
      setPrivateKey("");
      setOpenSnackbar(true);
      setOpenModal(false); // Close modal after registering
    } catch (error) {
      alert("Failed to register wallet. Please try again.");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        p={4}
        mb={4}
        sx={{ backgroundColor: theme.palette.background.paper }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: theme.typography.responsiveFontSizes,
          }}
          gutterBottom
        >
          Owner Wallets
        </Typography>

        {/* <Button
          variant="outlined"
          sx={{
            marginBottom: 2,
            marginTop: 2,
            fontSize: theme.typography.responsiveFontSizes,
            color: theme.palette.text.primary,
            borderColor: theme.palette.background.paper,
            backgroundColor: theme.palette.button.primary,
            "&:hover": {
              backgroundColor: theme.palette.button.secondary,
              borderColor: theme.palette.background.paper,
              transform: "scale(0.95)",
            },
          }}
          startIcon={<Add />}
          onClick={() => setOpenModal(true)} // Open modal on click
        >
          Register A New Coin
        </Button> */}

        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" fontWeight="medium" gutterBottom>
              Registered Wallets
            </Typography>
            <Divider sx={{ mb: 2 }} />
            {loading ? (
              <LoadingData />
            ) : (
              <List>
                {wallets.length > 0 ? (
                  wallets.map((wallet, index) => (
                    <ListItem
                      key={index}
                      sx={{ borderBottom: "1px solid #ddd" }}
                    >
                      <ListItemText
                        primary={`${wallet.coin} Address: ${wallet.address}`}
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    textAlign="center"
                  >
                    No wallets registered yet.
                  </Typography>
                )}
              </List>
            )}
          </CardContent>
        </Card>

        {/* Modal for Adding New Coin */}
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" fontWeight="medium" gutterBottom>
              Register a New Coin
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <TextField
              label="Coin Name"
              placeholder="e.g., BTC, ETH, USDT"
              variant="outlined"
              fullWidth
              value={newCoin}
              onChange={(e) => setNewCoin(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Wallet Address"
              placeholder="Enter your wallet address"
              variant="outlined"
              fullWidth
              value={newAddress}
              onChange={(e) => setNewAddress(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Private Key"
              placeholder="Enter your private key"
              variant="outlined"
              fullWidth
              value={privateKey}
              onChange={(e) => setPrivateKey(e.target.value)}
              sx={{ mb: 3 }}
            />
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={handleRegisterCoin}
            >
              Register Coin
            </Button>
          </Box>
        </Modal>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            variant="filled"
          >
            Coin registered successfully!
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}
