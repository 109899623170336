import apiServiceFetchers from "../../services/apiServiceFetchers";

export const fetchCoinsOnly = (token) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_COINS_REQUEST" });

    // Fetch coin data from API
    const fetchedCoins = await apiServiceFetchers.getAllCoins(token);

    if (!fetchedCoins || !Array.isArray(fetchedCoins.coins)) {
      throw new Error("Invalid response: Coins list missing or not an array");
    }

    // Dispatch only the coins list
    dispatch({ type: "FETCH_COINS_SUCCESS", payload: fetchedCoins.coins });

  } catch (error) {
    dispatch({
      type: "FETCH_COINS_FAILURE",
      error: error?.message || "An error occurred",
    });
  }
};

export const fetchCoinsAndBalances = (token) => async (dispatch, getState) => {
  try {
    dispatch({ type: "FETCH_COINS_REQUEST" });

    // Check if coins are already cached
    const { cachedCoinsWithBalances } = getState().coins;
    if (cachedCoinsWithBalances && cachedCoinsWithBalances.length > 0) {
      dispatch({
        type: "FETCH_COINS_SUCCESS",
        payload: cachedCoinsWithBalances,
      });
      return;
    }

    // Fetch coin data from API
    const fetchedCoins = await apiServiceFetchers.getAllCoins(token);

    if (!fetchedCoins || !fetchedCoins.coins) {
      throw new Error("Failed to fetch coins from API");
    }

    // Extract coin IDs
    const coinIds = fetchedCoins.coins.map((coin) => coin.coin_id);

    // Fetch balances for each coin concurrently
    const balanceRequests = coinIds.map(async (coinId) => {
      try {
        const response = await apiServiceFetchers.getTotalBalance(
          token,
          coinId
        );
        return { coinId, balance: response?.balance ?? 0 };
      } catch (err) {
        return { coinId, balance: 0 };
      }
    });

    const balancesArray = await Promise.all(balanceRequests);
    const balances = balancesArray.reduce((acc, { coinId, balance }) => {
      acc[coinId] = balance;
      return acc;
    }, {});

    // Merge balances into coins
    const coinsWithBalances = fetchedCoins.coins.map((coin) => ({
      ...coin,
      balance: balances[coin.coin_id] || 0,
    }));

    dispatch({ type: "FETCH_COINS_SUCCESS", payload: coinsWithBalances });
  } catch (error) {
    dispatch({
      type: "FETCH_COINS_FAILURE",
      error: error?.message || "An error occurred",
    });
  }
};
