import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_GOCHAPAA_API_ENDPOINT;

const apiService = {
  getUsers: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/allUsers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.user;
    } catch (error) {
      throw error;
    }
  },
  // to get the data of the current user
  getCurrentUserData: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the response contains the user data directly
    } catch (error) {
      throw error;
    }
  },

  // TO GET THE OWNERS WALLETS
   getOwnerWallets: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/owner-wallets/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the response contains the user data directly
    } catch (error) {
      throw error;
    }
  },


  // TO GET SPECIFIC USER BY ID
  getUserById: async (id, token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/users/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getProfilePicture: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/auth/me/profilePicture`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET TRANSACTION BY ID
  getTransactionById: async (transactionId, token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/payments/transactions/${transactionId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET TRANSACTION BY  user ID
  getTransactionByUserId: async (userId, token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/payments/transactions/user/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET All users
  getTotalNumberOfUsers: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/total-users`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET ALL TRANSACTIONS
  totalCompleteTransactions: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/total-complete-transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET all coins
  getTotalNumberOfCoins: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/total-coins`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET ALL TRANSACTIONS IN THE GRAPH

  getTransactionGraph: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/transaction-graph`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // To get total number of revenue
  getTotalNumberOfRevenue: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/total-revenue-amount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return { error: "Unable to fetch revenue at this time." };
    }
  },
  // TO GET THE TOTAL BALANCE
  getTotalBalance: async (token, coinId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/wallet-balance/${coinId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { balance: 0 };
      }
      throw error;
    }
  },

  // TO GET THE TOTAL TRANSACTION OF EACH ASSET completed-transactions
  getcompletedTransactionsEachAsset: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/sum-of-completed-transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // to get the transactions of all the coins
  getTotalTransactionAmount: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/sum-paycoin`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming the response contains the user data directly
    } catch (error) {
      throw error;
    }
  },

  // TO GET A COIN BY ITS ID
  getCoinById: async (id, token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/coinList/coin/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // TO GET THE TRANSACTIONS
  getTransactions: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/payments/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET THE TRANSACTIONS BY REFERENCES
  getTransactionsByReference: async (token, id) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/payments/transactions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET THE TRANSACTIONS COUNTS
  fetchTransactionCounts: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/dashboard/transaction-graph`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET THE Revenue data
  getRevenue: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/payments/transactions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET WITHDRAWAL  REQUESTS
  getAllWithdrawalRequests: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/withdrawal-request/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // to get withdrawal request by user id
  getAllWithdrawalRequestsByUserId: async (token, userId) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/withdrawal-request/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // GET WITHDRAWAL REQUEST BY STATUS
  getAllWithdrawalRequestsByStatus: async (token, status) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/withdrawal-request/by_status/${status}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET THE USER WALLET BALANCE
  getAllUserWalletBalances: async (id, token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/wallets/user-wallets/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET ALL THE COINS
  getAllCoins: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/coinList/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  
  getEarnings: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/earnings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getMpesaBalance: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/mpesa/balance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // TO GET NOTIFICATIONS
  getNotifications: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/notification/all`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUnreadNotifications: async (token) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/v1/notification/unread-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO GET FAQS
  getAllFaqs: async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/v1/faqs/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
