import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import apiServiceDelete from "../../services/apiServiceDelete";
import apiServiceActions from "../../services/apiServiceActions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Tooltip,
  useTheme,
  Grid,
  Divider,
} from "@mui/material";
import {
  Add,
  Edit,
  Delete,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import LoadingData from "../../components/Loaders/Loading";
import { fetchFaqs } from "../../redux/actions/faqsActions";

const Faqs = () => {
  const { currentUser } = useContext(AuthContext);

  const dispatch = useDispatch();
  const { loadingFaqs, loading, error } = useSelector((state) => state.faqs);
  const { token } = currentUser; // Ensure you retrieve the token appropriately

  const theme = useTheme();
  const [faqs, setFaqs] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentFaq, setCurrentFaq] = useState({
    id: null,
    question: "",
    answer: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    dispatch(fetchFaqs(token));
  }, [dispatch, token]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentFaq({ id: null, question: "", answer: "" });
    setIsEditMode(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentFaq({ ...currentFaq, [name]: value });
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async () => {
    if (isEditMode) {
      await updateFaq();
    } else {
      await createFaqEntry();
    }
    handleClose();
  };

  const createFaqEntry = async () => {
    try {
      const newFaq = { ...currentFaq, id: Date.now().toString() };
      const data = await apiServiceActions.createFaq(newFaq, currentUser.token);
      setFaqs([...faqs, data]);
      handleSnackbarOpen("FAQ created successfully", "success");
    } catch (error) {
      handleSnackbarOpen("Failed to create FAQ", "error");
    }
  };

  const updateFaq = async () => {
    try {
      const data = await apiServiceUpdate.updateFaqs(
        currentFaq.id,
        currentFaq,
        currentUser.token
      );
      setFaqs(faqs.map((faq) => (faq.id === currentFaq.id ? data : faq)));
      handleSnackbarOpen("FAQ updated successfully", "success");
    } catch (error) {
      handleSnackbarOpen("Failed to update FAQ", "error");
    }
  };

  const handleEdit = (faq) => {
    setCurrentFaq(faq);
    setIsEditMode(true);
    handleOpen();
  };

  const handleDelete = async (id) => {
    try {
      await apiServiceDelete.deleteFaqs(id, currentUser.token);
      setFaqs(faqs.filter((faq) => faq.id !== id));
      handleSnackbarOpen("FAQ deleted successfully", "success");
    } catch (error) {
      handleSnackbarOpen("Failed to delete FAQ", "error");
    }
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: theme.palette.background.paper }}>
      <Typography
        sx={{
          padding: 1,
          fontWeight: "bold",
          fontSize: theme.typography.responsiveFontSizes,
        }}
        gutterBottom
      >
        {" "}
        FAQs
      </Typography>
      <Divider />
      <Button
        variant="outlined"
        sx={{
          marginBottom: 2,
          marginTop: 2,
          fontSize: theme.typography.responsiveFontSizes,
          color: theme.palette.text.primary,
          borderColor: theme.palette.background.paper,
          backgroundColor: theme.palette.button.primary,
          "&:hover": {
            backgroundColor: theme.palette.button.secondary,
            borderColor: theme.palette.background.paper,
            transform: "scale(0.95)",
          },
        }}
        startIcon={<Add />}
        onClick={handleOpen}
      >
        Add FAQ
      </Button>
      {loading ? (
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoadingData />
        </Grid>
      ) : loadingFaqs.length === 0 ? (
        <Typography variant="subtitle1" sx={{ mt: 2, textAlign: "center" }}>
          No FAQs found
        </Typography>
      ) : (
        loadingFaqs
          .slice()
          .reverse()
          .map((faq) => (
            <Accordion
              key={faq.id}
              sx={{
                marginTop: 2,
                border: "1px solid",
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.background.paper,
              }}
            >
              <AccordionSummary
                sx={{
                  border: "1px solid",
                  fontSize: theme.typography.responsiveFontSizes,
                  backgroundColor: theme.palette.background.default,
                  borderColor: theme.palette.background.paper,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
              >
                <Typography
                  sx={{
                    padding: 1,
                    fontWeight: 700,
                    borderColor: theme.palette.background.paper,
                    fontSize: theme.typography.responsiveFontSizes,
                  }}
                >
                  {faq.question.charAt(0).toUpperCase() + faq.question.slice(1)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography sx={{ padding: 2 }}>
                  <ReactMarkdown>{faq.answer}</ReactMarkdown>
                </Typography>{" "}
                <Box>
                  <Tooltip title="Edit">
                    <IconButton
                      sx={{
                        marginRight: 2,
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.background.paper,
                        backgroundColor: theme.palette.button.primary,
                        "&:hover": {
                          backgroundColor: theme.palette.button.secondary,
                          borderColor: theme.palette.background.paper,
                          transform: "scale(0.95)",
                        },
                      }}
                      aria-label="edit"
                      onClick={() => handleEdit(faq)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      sx={{ backgroundColor: theme.palette.alert.warning }}
                      aria-label="delete"
                      onClick={() => handleDelete(faq.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: { xs: "100%" },
          border: "3px solid",
          borderColor: theme.palette.alert.disabled,
        }}
      >
        <DialogTitle>{isEditMode ? "Edit FAQ" : "Add FAQ"}</DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText sx={{ fontSize: 20 }}>
            {isEditMode
              ? "Edit the details of the FAQ below."
              : "Enter the details of the new FAQ below."}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="question"
            label="Question"
            fullWidth
            variant="outlined" // Change from "standard" to "outlined" for a better look
            value={currentFaq.question}
            onChange={handleChange}
            multiline // Allow multiple lines
            rows={2} // Increase this to make the box bigger
          />

          <TextField
            margin="dense"
            name="answer"
            label="Answer"
            fullWidth
            variant="outlined"
            value={currentFaq.answer}
            onChange={handleChange}
            multiline
            rows={6}
            placeholder="Use line breaks and * for bullets"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            sx={{
              borderRadius: 30,
              width: "50%",
              border: "1px solid",
              color: theme.palette.text.primary,
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.button.primary,
              "&:hover": {
                backgroundColor: theme.palette.button.secondary,
                borderColor: theme.palette.background.paper,
                transform: "scale(0.95)",
              },
            }}
          >
            {isEditMode ? "Update" : "Add Faq"}
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              borderRadius: 30,
              width: "50%",
              border: "1px solid",
              color: theme.palette.text.primary,
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.alert.warning,
              "&:hover": {
                backgroundColor: theme.palette.button.secondary,
                borderColor: theme.palette.background.paper,
                transform: "scale(0.95)",
              },
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default Faqs;
