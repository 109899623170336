import React, { useState, useEffect, useContext } from "react";
import {
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const RevenueWidget = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [usdRevenue, setUsdRevenue] = useState({});

  useEffect(() => {
    const fetchRevenueAndConvert = async () => {
      try {
        // Fetch total revenue per coin
        const revenueResponse =
          await apiServiceFetchers.getTotalNumberOfRevenue(currentUser.token);

        // Fetch all coins data (including prices)
        const coinsData = await apiServiceFetchers.getAllCoins(
          currentUser.token
        );
        const coinPrices = coinsData.coins.reduce((acc, coin) => {
          acc[coin.coin_id] = coin.price; // Store price by coin_id
          return acc;
        }, {});

        // Calculate total fees and commissions
        let totalFee = 0;
        let totalCommission = 0;

        Object.values(revenueResponse).forEach(({ fee, commission }) => {
          totalFee += parseFloat(fee);
          totalCommission += parseFloat(commission);
        });

        // Convert revenue to USD
        const convertedRevenue = Object.entries(revenueResponse).reduce(
          (acc, [coinId, balance]) => {
            const price = coinPrices[coinId] || 0; // Get price, default to 0 if missing
            const usdValue = parseFloat(balance) * price;

            acc[coinId] = usdValue; // Store USD value for each coin
            acc["TOTAL_USD"] = (acc["TOTAL_USD"] || 0) + usdValue;

            return acc;
          },
          {}
        );

        // Add totalFee and totalCommission to TOTAL_USD
        convertedRevenue["TOTAL_USD"] =
          (convertedRevenue["TOTAL_USD"] || 0) + totalFee + totalCommission;

        setUsdRevenue({
          ...convertedRevenue,
          totalFee,
          totalCommission,
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    fetchRevenueAndConvert();
  }, [currentUser.token]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.text.default,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* First Column: Icon */}
          <Grid
            item
            xs={12}
            sm={3}
            mb={4}
            display="flex"
            justifyContent={{ xs: "center", sm: "center", lg: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: 48, sm: 56 },
                height: { xs: 48, sm: 56 },
                borderRadius: "20%",
                backgroundColor: theme.palette.background.paper1,
                color: theme.palette.text.primary,
                border: "1px solid",
                borderColor: theme.palette.background.paper1,
                boxShadow: theme.shadows[2],
              }}
            >
              <PaymentsOutlinedIcon fontSize="large" />
            </Box>
          </Grid>

          {/* Second Column: Revenue Info */}
          <Grid item xs={12} sm={6}>
            <Stack direction="column">
              {/* Number of Transactions */}
              <Box display="flex" flexWrap="wrap">
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 22, md: 18, lg: 16 },
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  TOTAL REVENUE:
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: 20, sm: 22, md: 18, lg: 16 } }}
                >
                  {typeof usdRevenue?.TOTAL_USD === "number"
                    ? usdRevenue.TOTAL_USD.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}{" "}
                  USD
                </Typography>
              </Box>
              {/* Total Fee */}
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 22, md: 18, lg: 16 },
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  TRANSACTION FEE:
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: 20, sm: 22, md: 18, lg: 16 } }}
                >
                  {typeof usdRevenue?.totalFee === "number"
                    ? usdRevenue.totalFee.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}{" "}
                  USD
                </Typography>
              </Box>

              {/* Commission */}
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 22, md: 18, lg: 16 },
                    fontWeight: "bold",
                  }}
                >
                  WITHDRAWAL FEE:
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: 20, sm: 22, md: 18, lg: 16 } }}
                >
                  {typeof usdRevenue?.totalCommission === "number"
                    ? usdRevenue.totalCommission.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}{" "}
                  USD
                </Typography>
              </Box>
            </Stack>
          </Grid>

          {/* Third Column: Navigation Button */}
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={() => navigate("/revenue")}>
              <ArrowOutwardIcon
                sx={{
                  color: theme.palette.text.primary,
                  fontSize: { xs: 28, sm: 32 },
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default RevenueWidget;
