import React, { useState, useEffect, useContext } from "react";
import {
  IconButton,
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";
import { useNavigate } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"; // Updated Icon

const AssetsWidget = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [title] = useState("TOTAL ASSETS");
  const [totalCoins, setTotalcoins] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTotalCoins = async () => {
      try {
        const response = await apiServiceFetchers.getTotalNumberOfCoins(
          currentUser.token
        );
        setTotalcoins(response.totalCoins);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTotalCoins();
  }, [currentUser.token]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.alert.warning,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Grid container spacing={1} alignItems="center" mt={2}>
          {/* First Column: Icon */}
          <Grid item xs={3} display="flex">
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 56,
                height: 56,
                borderRadius: "20%",
                backgroundColor: theme.palette.background.paper1,
                color: theme.palette.text.primary,
                border: "1px solid",
                borderColor: theme.palette.background.paper1,
                boxShadow: theme.shadows[2],
              }}
            >
              <CategoryOutlinedIcon fontSize="large" />
            </Box>
          </Grid>

          {/* Second Column: Text & Count */}
          <Grid item xs={6}>
            <Stack direction="column" spacing={1}>
              <Typography
                className="title"
                sx={{
                  fontSize: { xs: 20, md: 18, lg: 16 },
                  fontWeight: "bold",
                }}
              >
                {title}:
              </Typography>
              <Typography
                className="totalUsers"
                sx={{ fontSize: { xs: 28, md: 24, lg: 20 } }}
              >
                {totalCoins}
              </Typography>
            </Stack>
          </Grid>

          {/* Third Column: Arrow Navigation */}
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => navigate("/coins")}>
              <ArrowOutwardIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default AssetsWidget;
