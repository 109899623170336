import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../Auth/AuthContext";
import { formatDate } from "../../constants/helpers";
import apiServiceActions from "../../services/apiServiceActions";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Avatar from "@mui/material/Avatar";
import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Zoom from "@mui/material/Zoom";
import Modal from "@mui/material/Modal";
import { deepOrange, red, green, blue } from "@mui/material/colors";
import VerifiedIcon from "@mui/icons-material/Verified";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import LoadingData from "../../components/Loaders/Loading";
import { Divider, Tab } from "@mui/material";
import TransactionById from "../../components/TransactionByUserId/TransactionById";
import {
  fetchCoinsAndBalances,
  fetchCoinsOnly,
} from "../../redux/actions/coinsActions";

const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_API_ENDPOINT;

const UserDetails = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userId } = useParams();
  const [value, setValue] = useState(0);
  const [rejectionMessage, setRejectionMessage] = useState("");
  const [showRejectInput, setShowRejectInput] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const coins = useSelector((state) => state.coins.allCoins || []);

  // Get coins from Redux state
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(fetchCoinsAndBalances(currentUser.token));

    const fetchUserDetails = async () => {
      try {
        const userDetails = await apiServiceFetchers.getUserById(
          userId,
          currentUser.token
        );
        setUserDetails(userDetails || {});
      } catch (error) {
        setUserDetails({});
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, [dispatch, userId, currentUser.token]);

  // to format the bances to various decimals
  useEffect(() => {
    if (currentUser.token) {
      dispatch(fetchCoinsOnly(currentUser.token));
    }
  }, [dispatch, currentUser.token]);

  const fetchUpdatedKycDocuments = async () => {
    try {
      const updatedUserDetails = await apiServiceFetchers.getUserById(
        userId,
        currentUser.token
      );
      setUserDetails(updatedUserDetails);
    } catch (error) {
      throw error;
    }
  };

  const handleApproveKyc = async () => {
    try {
      await apiServiceActions.approveKyc(userId, currentUser.token);

      setSnackbarMessage("KYC approved successfully!");
      setUserDetails({ ...userDetails, verificationStatus: "verified" });
      setSnackbarOpen(true);

      fetchUpdatedKycDocuments();

      // Wait for snackbar to show, then navigate to the next page
      setTimeout(() => {
        navigate("/users");
      }, 2000);
    } catch (error) {
      setSnackbarMessage("Failed to approve KYC");
      setSnackbarOpen(true);
    }
  };

  // Handle Reject KYC API call
  const handleRejectKyc = async () => {
    if (!rejectionMessage.trim()) return;

    try {
      await apiServiceActions.rejectKyc(
        userId,
        rejectionMessage,
        currentUser.token
      );
      setSnackbarMessage("KYC rejected successfully!");
      setUserDetails({ ...userDetails, verificationStatus: "unverified" });
      fetchUpdatedKycDocuments();
      // Route to the next page after approval
      navigate("/users");
    } catch (error) {
      setSnackbarMessage("Failed to reject KYC");
    } finally {
      setSnackbarOpen(true);
      setShowRejectInput(false);
      setRejectionMessage("");
    }
  };

  const setColorForStatus = (status) => {
    switch (status) {
      case "verified":
        return green[500];
      case "unverified":
        return red[500];
      case true:
        return green[500];
      case false:
        return red[500];
      default:
        return blue[500];
    }
  };

  const setIconForStatus = (status) => {
    switch (status) {
      case "verified":
        return <VerifiedIcon color="success" />;
      case "unverified":
        return <GppMaybeIcon sx={{ color: red[500] }} />;
      case true:
        return <VerifiedIcon color="success" />;
      case false:
        return <GppMaybeIcon sx={{ color: red[500] }} />;
      default:
        return <GppMaybeIcon sx={{ color: red[500] }} />;
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoadingData />
        </Grid>
      </Box>
    );
  }

  if (!userDetails) {
    return <Box>Error loading user details...</Box>;
  }

  // Remove duplicates based on filePath and originalName
  const uniqueDocuments = userDetails.kycDocuments
    ? userDetails.kycDocuments.filter(
        (doc, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.filePath === doc.filePath && t.originalName === doc.originalName
          )
      )
    : [];

  // TO REMOVE THE BALANCES WITH 0;
  const filteredWallets = userDetails.userWallets.filter(
    (wallet) => wallet.balance && wallet.balance !== 0
  );

  return (
    <ThemeProvider theme={theme}>
      <Box padding={2} sx={{ overflowX: "auto" }}>
        <Grid container xs={12} spacing={3}>
          {/* user profile */}
          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                padding: 3,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.07)"
                    : "whitesmoke",
                minHeight: 300,
              }}
            >
              <Stack spacing={2} paddingBottom={2}>
                <Typography
                  variant="h6"
                  component="h2"
                  fontWeight={"bold"}
                  sx={{
                    fontSize: { xs: 40, md: 20, lg: 16 },
                    textAlign: "center",
                  }}
                >
                  User Profile
                </Typography>
                <Divider />
              </Stack>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    sx={{ bgcolor: deepOrange[500], width: 60, height: 60 }}
                    alt={userDetails.firstName || "User"}
                    src={
                      Array.isArray(userDetails.profilePictures) &&
                      userDetails.profilePictures.length > 0
                        ? userDetails.profilePictures[0]
                        : ""
                    }
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                  >
                    {userDetails.firstName.toUpperCase()}{" "}
                    {userDetails.lastName.toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
              <Stack spacing={2} paddingTop={3}>
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                    onClick={handleApproveKyc}
                  >
                    Approve Kyc
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                    onClick={() => {
                      if (userDetails?.verificationStatus === "unverified") {
                        setSnackbarMessage(
                          `${userDetails.firstName} has not uploaded KYC documents yet...`
                        );
                        setSnackbarSeverity("error");
                        setSnackbarOpen(true);

                        // // Wait for the snackbar to be shown before navigating
                        // setTimeout(() => {
                        //   navigate("/users");
                        // }, 2000); // Delay navigation by 2 seconds (adjust timing as needed)

                        return;
                      }

                      setShowRejectInput(!showRejectInput);
                    }}
                  >
                    Reject Kyc
                  </Button>
                </Stack>
                {/* Pop-up Text Field for Rejection */}
                {showRejectInput && (
                  <Stack spacing={1} sx={{ width: "100%", maxWidth: 400 }}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant="outlined"
                      label="Reason for rejection"
                      value={rejectionMessage}
                      onChange={(e) => setRejectionMessage(e.target.value)}
                    />
                    <Stack direction="row" spacing={1}>
                      <Button
                        onClick={handleRejectKyc}
                        color="success"
                        variant="contained"
                        disabled={!rejectionMessage.trim()}
                      >
                        Submit
                      </Button>
                      <Button
                        onClick={() => setShowRejectInput(false)}
                        color="error"
                      >
                        Cancel
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Card>
          </Grid>
          {/* user Info */}
          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                padding: 3,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.07)"
                    : "whitesmoke",
                height: 300,
              }}
            >
              <Typography
                variant="h6"
                component="h2"
                marginBottom={1}
                fontWeight={"bold"}
                sx={{
                  fontSize: { xs: 34, md: 20, lg: 16 },
                  textAlign: "center",
                }}
              >
                User Information
              </Typography>
              <Divider />
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>Joined:</strong> {formatDate(userDetails.createdAt)}
              </Typography>

              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>Email:</strong> {userDetails.email}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>DOB:</strong>{" "}
                {userDetails.dateOfBirth
                  ? formatDate(userDetails.dateOfBirth)
                  : "-"}
              </Typography>
              <Typography
                variant="body1"
                component="p"
                mb={1}
                sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
              >
                <strong>Phone:</strong> {userDetails.phone}
              </Typography>
              <Divider />
              {/* <Typography
                variant="h6"
                component="h2"
                marginBottom={2}
                sx={{ fontSize: { xs: 40, md: 20, lg: 16 } }}
                marginTop={0}
                fontWeight={"bold"}
              >
                Verification
              </Typography> */}
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  style={{ marginRight: "8px" }}
                >
                  <strong>Account Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.verificationStatus),
                  }}
                >
                  {setIconForStatus(userDetails.verificationStatus)}
                </Typography>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  component="p"
                  style={{ marginRight: "8px" }}
                >
                  <strong>Phone Number Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.isPhoneVerified),
                  }}
                >
                  {setIconForStatus(userDetails.isPhoneVerified)}
                </Typography>
              </Box>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="body1"
                  component="p"
                  sx={{ fontSize: { xs: 34, md: 20, lg: 16 } }}
                  style={{ marginRight: "8px" }}
                >
                  <strong>Email Status:&nbsp;</strong>
                </Typography>
                <Typography
                  variant="body1"
                  component="p"
                  style={{
                    color: setColorForStatus(userDetails.isEmailVerified),
                  }}
                >
                  {setIconForStatus(userDetails.isEmailVerified)}
                </Typography>
              </Box>
            </Card>
          </Grid>
          {/* User Balances */}
          <Grid item xs={12} lg={4}>
            <Card
              sx={{
                padding: 3,
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 255, 255, 0.07)"
                    : "whitesmoke",
                height: 300, // Keep the fixed height
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Stack spacing={2} paddingBottom={1}>
                <Typography
                  variant="h6"
                  component="h2"
                  fontWeight={"bold"}
                  sx={{
                    fontSize: { xs: 40, md: 20, lg: 16 },
                    textAlign: "center",
                  }}
                >
                  Wallet Balances
                </Typography>
              </Stack>
              <Divider />

              {/* Make Stack fill available space */}
              <Stack
                spacing={2}
                paddingTop={1}
                sx={{ flex: 1, overflow: "hidden" }}
              >
                {userDetails.userWallets &&
                userDetails.userWallets.length > 0 ? (
                  <Box
                    className="scrollable-container"
                    sx={{
                      flex: 1,
                      overflowY: "auto",
                      padding: 1,
                      scrollbarWidth: "thin",
                      scrollbarColor: "#888 #f1f1f1",
                      "&::-webkit-scrollbar": { width: "8px" },
                      "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#888",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#555",
                      },
                    }}
                  >
                    {filteredWallets.length > 0 ? (
                      filteredWallets.map((wallet, index) => {
                        const coinId =
                          wallet.coinList?.coinId ?? "Unknown Coin";
                        const balance = wallet.balance;

                        // Find the corresponding coin details
                        const matchingCoin = coins.find(
                          (c) => c.coin_id === coinId
                        );

                        // Extract decimal points (default to 2 and 8)
                        const minDecimals =
                          matchingCoin?.minimum_decimal_point ?? 2;
                        const maxDecimals =
                          matchingCoin?.maximum_decimal_point ?? 8;

                        // Format balance
                        const formattedBalance = Number(balance).toLocaleString(
                          "en-US",
                          {
                            minimumFractionDigits: minDecimals,
                            maximumFractionDigits: maxDecimals,
                          }
                        );

                        return (
                          <Stack
                            key={wallet.id || index}
                            direction="row"
                            spacing={3}
                            alignItems="center"
                            justifyContent="space-between"
                            mb={2}
                            sx={{ width: "100%" }}
                          >
                            {/* Coin Label - First Column */}
                            <Typography
                              variant="body1"
                              component="span"
                              sx={{
                                fontSize: { xs: 18, md: 16, lg: 14 },
                                fontWeight: "bold",
                                flex: 1,
                              }}
                            >
                              {matchingCoin?.coin_label || coinId}:
                            </Typography>

                            {/* Coin Balance - Second Column */}
                            <Typography
                              variant="body2"
                              component="span"
                              sx={{
                                fontSize: { xs: 16, md: 14, lg: 14 },
                                fontWeight: "medium",
                                color: balance > 0 ? "#333" : "#999",
                                textAlign: "left",
                                flex: 1,
                              }}
                            >
                              {formattedBalance}
                            </Typography>
                          </Stack>
                        );
                      })
                    ) : (
                      <Typography
                        variant="body1"
                        component="p"
                        sx={{
                          fontSize: { xs: 18, md: 16, lg: 14 },
                          textAlign: "center",
                          color: "#999",
                        }}
                      >
                        You have no available balances
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Typography variant="body1" component="p">
                    No wallets found
                  </Typography>
                )}
              </Stack>
            </Card>
          </Grid>
        </Grid>

        {/* The tabs */}
        <ThemeProvider theme={theme}>
          <Card sx={{ marginTop: 3 }}>
            <Grid container>
              {/* TRANSACTIONS */}
              <Grid item xs={6}>
                <Tab
                  label="Transactions"
                  onClick={() => handleChange(null, 0)}
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    padding: "16px",
                    borderBottom:
                      value === 0
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              </Grid>
              {/* KYC DOCUMENTS */}
              <Grid item xs={6}>
                <Tab
                  label="KYC Documents"
                  onClick={() => handleChange(null, 1)}
                  sx={{
                    color: theme.palette.text.primary,
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    padding: "16px",
                    borderBottom:
                      value === 1
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <Box
            flex="1"
            overflow="auto"
            sx={{
              color: theme.palette.text.default,
              fontWeight: "bold",
              marginTop: 2,
            }}
          >
            {value === 0 && (
              <TransactionById
                sx={{
                  color: theme.palette.text.default,
                  fontWeight: "bold",
                  width: "100%",
                }}
              />
            )}
            {value === 1 && (
              <Grid item xs={12}>
                <Card>
                  {uniqueDocuments.length > 0 ? (
                    <ImageList
                      variant="masonry"
                      cols={3}
                      gap={10}
                      // sx={{ padding: 1 }}
                    >
                      {uniqueDocuments.map((document) => {
                        if (document.filePath) {
                          const imageUrl = `${IMAGE_BASE_URL}${document.filePath}`;
                          return (
                            <Zoom
                              in={true}
                              key={document.id}
                              sx={{ height: 200, width: "100%" }}
                            >
                              
                              <ImageListItem
                                sx={{ height: "100%", width: "100%" }}
                              >
                                <ImageListItemBar
                                  title={document.type}
                                  subtitle={`Uploaded on: ${new Date(
                                    document.createdAt
                                  ).toLocaleDateString()}`}
                                  position="top"
                                  style={{ marginBottom: 80 }}
                                />
                                <img
                                  src={imageUrl}
                                  alt={document.type}
                                  loading="lazy"
                                  style={{
                                    objectFit: "contain",
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  onClick={() => handleImageClick(imageUrl)}
                                  onError={(e) => {
                                    e.target.src =
                                      "path/to/placeholder-image.jpg";
                                  }}
                                />
                              </ImageListItem>
                            </Zoom>
                          );
                        }
                        return null;
                      })}
                    </ImageList>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      textAlign="center"
                      padding={1}
                    >
                      No KYC documents found
                    </Typography>
                  )}
                </Card>
              </Grid>
            )}
          </Box>
        </ThemeProvider>
        <Snackbar
          open={snackbarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            severity={snackbarSeverity}
            onClose={() => setSnackbarOpen(false)}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Modal
          open={!!selectedImage}
          onClose={handleCloseModal}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "90%",
              maxHeight: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <img
              src={selectedImage}
              alt="Full size"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>
  );
};

export default UserDetails;
