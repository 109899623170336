import React, { useState, useEffect, useContext } from "react";
import {
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../Auth/AuthContext";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward"; // Updated Icon
import { useNavigate } from "react-router-dom";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";
import "./UserWidget.scss";

const UserWidget = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [title] = useState("TOTAL USERS");
  const [loading, setLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState(null);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const response = await apiServiceFetchers.getTotalNumberOfUsers(
          currentUser.token
        );

        setTotalUsers(response.totalUsers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchTotalUsers();
  }, [currentUser.token]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.text.default,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Grid container spacing={1} alignItems="center" mt={2}>
          {/* First Column: Icon */}
          <Grid item xs={4} display="flex" >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 56,
                height: 56,
                borderRadius: "20%",
                backgroundColor: theme.palette.background.paper1,
                color: theme.palette.text.primary,
                border: "1px solid",
                borderColor: theme.palette.background.paper1,
                boxShadow: theme.shadows[2],
              }}
            >
              <GroupOutlinedIcon fontSize="large" />
            </Box>
          </Grid>

          {/* Second Column: Text & Count */}
          <Grid item xs={4}>
            <Stack direction="column" spacing={1}>
              <Typography
                className="title"
                sx={{ fontSize: { xs: 20, md: 18, lg: 16 } ,fontWeight:"bold"}}
              >
                {title}:
              </Typography>
              <Typography
                className="totalUsers"
                sx={{ fontSize: { xs: 28, md: 24, lg: 20 } }}
              >
                {totalUsers}
              </Typography>
            </Stack>
          </Grid>

          {/* Third Column: Arrow Navigation */}
          <Grid item xs={4} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => navigate("/users")}>
              <ArrowOutwardIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default UserWidget;
