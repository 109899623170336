import React, { useEffect,useContext } from "react";
import {
  Grid,
  Box,
  Paper,
  Typography,
  Button,
  Snackbar,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector ,useDispatch} from "react-redux";
import { ThemeProvider } from "@emotion/react";
import { SkeletonShowTableCards } from "../../components/Loaders/Skeleton";
import { AuthContext } from "../../Auth/AuthContext";
import { fetchCoinsOnly } from "../../redux/actions/coinsActions";

const Transactions = ({
  transactions,
  loading,
  openSnackbar,
  snackbarMessage,
  handleSnackbarClose,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);

  const coins = useSelector((state) => state.coins.allCoins || []);

  useEffect(() => {
    if (currentUser.token) {
      dispatch(fetchCoinsOnly(currentUser.token));
    }
  }, [dispatch, currentUser.token]);


  const formatDate = (date) => {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";

    return parsedDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 200,
      renderCell: (params) => {
        const truncatedId = params.value.split("-")[0].toUpperCase();
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {truncatedId}
          </Box>
        );
      },
    },
    { field: "transaction_type", headerName: "Transaction Type", flex: 1 },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "transaction_kind",
      headerName: "Coin",
      flex: 1,
      renderCell: (params) => {
        return params.value ? params.value.toUpperCase() : "";
      },
    },
    // {
    //   field: "pay_coin_amount",
    //   headerName: "Transaction Amount",
    //   width: 200,
    //   renderCell: (params) => {
    //     if (params.value === undefined || params.value === null) {
    //       return (
    //         <Box
    //           p={1}
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //           }}
    //         >
    //           {""}
    //         </Box>
    //       );
    //     }

    //     const amount = parseFloat(params.value);

    //     if (isNaN(amount) || !isFinite(amount)) {
    //       return (
    //         <Box
    //           p={1}
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             color: "red",
    //           }}
    //         >
    //           Error
    //         </Box>
    //       );
    //     }

    //     const isCrypto = params.row.transaction_kind === "mpesa";

    //     const formattedAmount = isCrypto
    //       ? amount.toFixed(8)
    //       : amount.toFixed(2);

    //     return (
    //       <Box
    //         p={1}
    //         sx={{
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //         }}
    //       >
    //         {formattedAmount}
    //       </Box>
    //     );
    //   },
    // },
    {
      field: "pay_coin_amount",
      headerName: "Transaction Amount",
      width: 200,
      renderCell: (params) => {
        if (!params.value) return <Box p={1}>{""}</Box>;

        const amount = parseFloat(params.value);

        if (isNaN(amount))
          return (
            <Box p={1} sx={{ color: "red" }}>
              Error
            </Box>
          );

        // Find the coin based on transaction_kind
        const matchedCoin = coins.find((coin) => {
          const coinId = coin.coin_id?.trim().toLowerCase();
          const transactionKind = params.row.transaction_kind
            ?.trim()
            .toLowerCase();
          return coinId === transactionKind;
        });

        // Set decimal points based on matched coin
        const minDecimals = matchedCoin?.minimum_decimal_point ?? 2;
        const maxDecimals = matchedCoin?.maximum_decimal_point ?? 8;

        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: minDecimals,
          maximumFractionDigits: maxDecimals,
        });

        return <Box p={1}>{formattedAmount}</Box>;
      },
    },
    {
      field: "transaction_status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let backgroundColor;
        switch (params.value) {
          case "pending":
            backgroundColor = theme.palette.alert.disabled;
            break;
          case "complete":
            backgroundColor = "green";
            break;
          case "failed":
            backgroundColor = "red";
            break;
          default:
            backgroundColor = "transparent";
            break;
        }
        return (
          <span
            className="status"
            style={{
              display: "flex",
              opacity: "0.7",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor,
              padding: "5px",
              borderRadius: "4px",
              color: "#fff",
              width: "100px",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
  ];

  const handleLoadMore = () => {
    navigate("/transactionpage");
  };

  if (loading) {
    return <SkeletonShowTableCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Typography
        variant="h6"
        sx={{ marginBottom: 2, fontSize: theme.typography.responsiveFontSizes }}
      >
        Latest Transactions
      </Typography>
      <Paper elevation={3} sx={{ marginBottom: "20px" }}>
        <DataGrid
          rows={transactions}
          columns={columns}
          autoHeight
          components={{
            NoRowsOverlay: () => (
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: { xs: 38, md: 20, lg: 16 },
                }}
              >
                No transactions available
              </Typography>
            ),
          }}
          hideFooterPagination
          rowClassName={(params) =>
            `${params.index % 2 ? "odd-row" : "even-row"}`
          }
          componentsProps={{
            cell: {
              sx: {
                fontSize: { xs: "1rem", md: "1rem", lg: "0.875rem" },
              },
            },
            columnHeader: {
              sx: {
                fontSize: { xs: "1rem", md: "1rem", lg: "0.875rem" },
              },
            },
          }}
        />
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleLoadMore}
              sx={{
                fontSize: { xs: "1rem", md: "1rem", lg: "0.875rem" },
                marginBottom: "4px",
                borderColor: theme.palette.alert.disabled,
                color: theme.palette.text.primary,
              }}
            >
              Load More
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </ThemeProvider>
  );
};

export default Transactions;
