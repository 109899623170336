import React, { useState, useMemo, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RequireAdminAuth } from "./Auth/Auth";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AuthContext } from "./Auth/AuthContext";
import { Box } from "@mui/material";
import Layout from "./components/layout/Layout";
import Home from "./Pages/Home/Home";
import Login from "./Auth/Login";
import Logout from "./Auth/Logout";
import List from "./Pages/list/List";
import Single from "./Pages/single/Single";
import Transactions from "./Pages/transactions/Transactions";
import UserDetails from "./Pages/userdetails/UserDetails";
import Coins from "./Pages/coins/Coins";
import Notifications from "./Pages/notifications/Notifications";
import Faqs from "./Pages/faqs/Faqs";
import { createAppTheme } from "./constants/theme";
import Profile from "./Pages/profile/Profile";
import TransactionPage from "./Pages/transactions/MainPage";
import TermsOfUse from "./Pages/Footer/TermsOfUse";
import PrivacyPolicy from "./Pages/Footer/PrivacyPolicy";
import Revenue from "./Pages/revenue/Revenue";
import Wallets from "./Pages/wallets/Wallets";
import AddCoin from "./Pages/coins/AddCoin";

export default function App() {
  const { currentUser } = useContext(AuthContext);

  const [mode, setMode] = useState(
    () => localStorage.getItem("mode") || "light"
  );

  const toggleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    setMode(newMode);
    localStorage.setItem("mode", newMode);
  };

  const prefersDarkMode = useMemo(
    () =>
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
    []
  );

  const theme = useMemo(() => createAppTheme(mode), [mode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Home />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route path="users">
              <Route
                index
                element={
                  <RequireAdminAuth currentUser={currentUser}>
                    <Layout
                      toggleMode={toggleMode}
                      prefersDarkMode={prefersDarkMode}
                    >
                      <List />
                    </Layout>
                  </RequireAdminAuth>
                }
              />
              <Route
                path=":userId"
                element={
                  <RequireAdminAuth currentUser={currentUser}>
                    <Layout
                      toggleMode={toggleMode}
                      prefersDarkMode={prefersDarkMode}
                    >
                      <Single />
                    </Layout>
                  </RequireAdminAuth>
                }
              />
              <Route
                path="userdetails/:userId"
                element={
                  <RequireAdminAuth currentUser={currentUser}>
                    <Layout
                      toggleMode={toggleMode}
                      prefersDarkMode={prefersDarkMode}
                    >
                      <UserDetails />
                    </Layout>
                  </RequireAdminAuth>
                }
              />
            </Route>
            <Route
              path="wallets"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Wallets />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="transactions"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Transactions />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="transactionpage"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <TransactionPage />
                  </Layout>
                </RequireAdminAuth>
              }
            />

            <Route
              path="coins"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Coins />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="/add-coin"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <AddCoin />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="revenue"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Revenue />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="notifications"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Notifications />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="faqs"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Faqs />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="termsOfUse"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <TermsOfUse />
                  </Layout>
                </RequireAdminAuth>
              }
            />

            <Route
              path="privacyPolicy"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <PrivacyPolicy />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route
              path="profile"
              element={
                <RequireAdminAuth currentUser={currentUser}>
                  <Layout
                    toggleMode={toggleMode}
                    prefersDarkMode={prefersDarkMode}
                  >
                    <Profile />
                  </Layout>
                </RequireAdminAuth>
              }
            />
            <Route path="products">
              <Route
                index
                element={
                  <RequireAdminAuth currentUser={currentUser}>
                    <Layout
                      toggleMode={toggleMode}
                      prefersDarkMode={prefersDarkMode}
                    >
                      <List />
                    </Layout>
                  </RequireAdminAuth>
                }
              />
              <Route
                path=":productId"
                element={
                  <RequireAdminAuth currentUser={currentUser}>
                    <Layout
                      toggleMode={toggleMode}
                      prefersDarkMode={prefersDarkMode}
                    >
                      <Single />
                    </Layout>
                  </RequireAdminAuth>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
}
