import React from "react";
import { Box } from "@mui/material";
import { trio } from "ldrs";

trio.register();

export default function LoadingData() {
  return (
    <Box>
      <l-trio size="60" speed="1.3" color="black"></l-trio>
    </Box>
  );
}
