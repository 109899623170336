const initialState = {
  allCoins: [],
  cachedCoinsWithBalances: [],
  loadingCoins: false,
  error: null,
};

export const coinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_COINS_REQUEST":
      return { ...state, loadingCoins: true, error: null };

    // case "FETCH_COINS_REQUEST":
    //   return { ...state, loadingCoins: true };

    case "FETCH_COINS_SUCCESS":
      return {
        ...state,
        allCoins: action.payload,
        cachedCoinsWithBalances: action.payload,
        loadingCoins: false,
      };

    case "FETCH_COINS_FAILURE":
      return { ...state, error: action.error, loadingCoins: false };

    default:
      return state;
  }
};
