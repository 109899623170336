import React, { useState, useEffect, useContext } from "react";
import {
  IconButton,
  useTheme,
  ThemeProvider,
  Card,
  Box,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import { AuthContext } from "../../Auth/AuthContext";
import { SkeletonShowTotalsCards } from "../Loaders/Skeleton";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const TotalTransactions = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [totalCompleteTransactions, setTotalCompleteTransactions] =
    useState(null);
  const [totalTransactionSumUSD, setTotalTransactionSumUSD] = useState(null);

  useEffect(() => {
    const fetchTotalTransactions = async () => {
      try {
        const response = await apiServiceFetchers.totalCompleteTransactions(
          currentUser.token
        );
        setTotalCompleteTransactions(response);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchTotalTransactions();
  }, [currentUser.token]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const transactionsResponse =
          await apiServiceFetchers.getTotalTransactionAmount(currentUser.token);
        const transactionsData = Array.isArray(transactionsResponse?.data)
          ? transactionsResponse.data
          : [];

        // Sum up the total amounts without conversion
        const totalSumInUSD = transactionsData.reduce(
          (sum, { totalAmount }) => sum + parseFloat(totalAmount),
          0
        );
        setTotalTransactionSumUSD(totalSumInUSD);
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [currentUser.token]);

  if (loading) {
    return <SkeletonShowTotalsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Card
        sx={{
          padding: 2,
          width: "100%",
          height: "100%",
          borderRight: "5px solid",
          borderColor: theme.palette.alert.warning,
          borderTopRightRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Grid container spacing={2} alignItems="center">
          {/* First Column: Icon */}
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={{ xs: "center", sm: "center", lg: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: { xs: 48, sm: 56 },
                height: { xs: 48, sm: 56 },
                borderRadius: "20%",
                backgroundColor: theme.palette.background.paper1,
                color: theme.palette.text.primary,
                border: "1px solid",
                borderColor: theme.palette.background.paper1,
                boxShadow: theme.shadows[2],
              }}
            >
              <LayersOutlinedIcon fontSize="large" />
            </Box>
          </Grid>

          {/* Second Column: Transactions Info */}
          <Grid item xs={12} sm={6}>
            <Stack direction="column" spacing={1}>
              {/* Number of Transactions */}
              <Box display="flex" gap={0.2} flexWrap="wrap">
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 22, md: 18, lg: 16 },
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  NO. OF TRANSACTIONS:
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 24, md: 18, lg: 16 },
                  }}
                >
                  {totalCompleteTransactions?.totalCompleteTransactions || 0}
                </Typography>
              </Box>

              {/* Total Transactions */}
              <Box display="flex" gap={0.2} flexWrap="wrap">
                <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 22, md: 18, lg: 16 },
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  TOTAL TRANSACTIONS:
                </Typography>
                {loading || totalTransactionSumUSD === null ? (
                  <Typography>Loading...</Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24, md: 18, lg: 16 },
                    }}
                  >
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(totalTransactionSumUSD)}{" "}
                    USD
                  </Typography>
                )}
                
                {/* <Typography
                  sx={{
                    fontSize: { xs: 20, sm: 24, md: 18, lg: 16 },
                  }}
                >
                  USD
                </Typography> */}
              </Box>
            </Stack>
          </Grid>

          {/* Third Column: Arrow Navigation */}
          <Grid
            item
            xs={12}
            sm={3}
            display="flex"
            justifyContent={{ xs: "center", sm: "flex-end" }}
          >
            <IconButton onClick={() => navigate("/transactionpage")}>
              <ArrowOutwardIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </ThemeProvider>
  );
};

export default TotalTransactions;
