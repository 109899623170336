import React, { useEffect, useState, useContext, useCallback } from "react";
import { Box, Typography, Button, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import { SkeletonShowGraphChartsCards } from "../Loaders/Skeleton";
import { useSpring, animated } from "@react-spring/web"; // Import react-spring

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const generateCoinColors = (coins, theme) => {
  const colorPalette = [
    theme.palette.alert.success,
    theme.palette.alert.warning,
    theme.palette.alert.disabled,
    theme.palette.background.paper1,
    theme.palette.background.paper2,
    theme.palette.primary.main,
    theme.palette.secondary.main,
  ];

  // Shuffle the palette to distribute colors
  const shuffledPalette = colorPalette.sort(() => 0.5 - Math.random());
  const coinColors = {};

  coins.forEach((coin, index) => {
    coinColors[coin] = shuffledPalette[index % shuffledPalette.length];
  });

  return coinColors;
};

const Chart = ({ title }) => {
  const theme = useTheme();
  const { currentUser, graphData, dispatch } = useContext(AuthContext);
  const [data, setData] = useState({});
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dynamicTitle, setDynamicTitle] = useState("Last 6 Months Revenue");

  const handleTimeFrameChange = useCallback(
    (newTimeFrame) => {
      let dataToFormat = {};
      let newTitle = "";

      if (newTimeFrame === "lastSixMonths") {
        dataToFormat = graphData?.lastSixMonths || {};
        newTitle = "Last 6 Months Revenue";
      } else if (newTimeFrame === "yearly") {
        dataToFormat = graphData?.lastYear || {};
        newTitle = "Yearly Revenue";
      }

      setDynamicTitle(newTitle);
      const { formattedData, labelArray } = formatGraphData(dataToFormat);
      setData(formattedData);
      setLabels(labelArray);
    },
    [graphData]
  );

  const formatGraphData = (graphData) => {
    const formattedData = {};
    const labelSet = new Set();

    // Extracting unique months
    Object.values(graphData).forEach((coinData) => {
      coinData.forEach((entry) => {
        labelSet.add(new Date(entry.month).toISOString().split("T")[0]); // Extract date string
      });
    });

    const labelArray = Array.from(labelSet).sort(
      (a, b) => new Date(a) - new Date(b)
    ); // Sort dates

    // Initialize data structure
    Object.keys(graphData).forEach((coin) => {
      formattedData[coin] = []; // Initialize an array for each coin
      graphData[coin].forEach(({ month, count }) => {
        const formattedDate = new Date(month).toISOString().split("T")[0]; // Normalize date format
        labelSet.add(formattedDate);
        formattedData[coin].push(parseInt(count, 10));
      });
    });

    return { formattedData, labelArray };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentUser && currentUser.token) {
        try {
          const fetchedGraphData = await apiServiceFetchers.getTransactionGraph(
            currentUser.token
          );
          dispatch({ type: "SET_GRAPH_DATA", payload: fetchedGraphData });
          handleTimeFrameChange("lastSixMonths");
        } catch (error) {
          setLoading(false);
        }
      }
    };

    if (!graphData) {
      fetchData();
    } else {
      handleTimeFrameChange("lastSixMonths");
      setLoading(false);
    }
  }, [currentUser, dispatch, graphData, handleTimeFrameChange]);

  // Generate dynamic colors
  const coinColors = generateCoinColors(Object.keys(data), theme);

  const chartData = {
    labels: labels,
    datasets: Object.keys(data).map((coin) => ({
      label: coin,
      data: data[coin],
      borderColor: coinColors[coin],
      backgroundColor: coinColors[coin],
      fill: false,
      fontSize: theme.typography.responsiveFontSizes,
      tension: 0.4,
    })),
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: true,
        text: title,
        fontSize: theme.typography.responsiveFontSizes,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
          fontSize: theme.typography.responsiveFontSizes,
        },
        ticks: {
          beginAtZero: true,
        },
        grid: {
          drawBorder: false,
        },
      },
      y: {
        title: {
          display: true,
          text: "Balance",
          fontSize: theme.typography.responsiveFontSizes,
        },
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  // Spring animation for title transition
  const [springProps, api] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(-20px)",
  }));

  useEffect(() => {
    api.start({
      opacity: 1,
      transform: "translateY(0px)",
      config: { duration: 300 },
    });
  }, [title, api]);

  if (loading) {
    return <SkeletonShowGraphChartsCards theme={theme} />;
  }

  return (
    <Box
      className="chart"
      sx={{
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        borderRadius: "12px",
        padding: "16px",
        height: 500,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <animated.div style={springProps}>
          <Typography
            sx={{ fontSize: { xs: 38, md: 20, lg: 16 }, fontWeight: 600 }}
          >
            {dynamicTitle}
          </Typography>
        </animated.div>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontSize: { xs: 38, md: 20, lg: 16 },
            backgroundColor: theme.palette.button.primary,
            color: theme.palette.text.primary,
            border: "1px solid",
            borderColor: theme.palette.background.default,
          }}
          onClick={() => handleTimeFrameChange("lastSixMonths")}
        >
          Last 6 Months
        </Button>
        <Button
          variant="outlined"
          sx={{
            fontSize: { xs: 38, md: 20, lg: 16 },
            backgroundColor: theme.palette.button.primary,
            color: theme.palette.text.primary,
            border: "1px solid",
            borderColor: theme.palette.background.default,
          }}
          onClick={() => handleTimeFrameChange("yearly")}
        >
          Yearly
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "350px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Line
          sx={{ fontSize: { xs: 38, md: 20, lg: 16 } }}
          data={chartData}
          options={chartOptions}
        />
      </Box>
    </Box>
  );
};

export default Chart;
