import { format } from "date-fns";

// Mapping of currency formats
export const currencyFormats = {
  KES: (price) => `KES ${price.toLocaleString()}`,
  ETH: (price) => `ETH ${price}`,
  BTC: (price) => `BTC ${price}`,
  USDT: (price) => `USDT ${price}`,
};

// Utility function to handle API errors
export const handleApiError = (error) => {
  throw error
};

// Utility function to format a date string
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "dd MMM yyyy");
};

//  round off to 2 decimal places
export const toDecimal = (theform) => {
  var num = theform.original ? theform.original.value : 0;
  var rounded = theform.rounded;

  // Check if rounded is defined before setting its value
  if (rounded) {
    // Convert num to a string and split it into integer and decimal parts
    var [integerPart, decimalPart] = num.toString().split(".");

    // If decimalPart is not defined, set it to '000'
    decimalPart = decimalPart || "000";

    // If decimalPart has more than 3 digits, truncate it
    decimalPart = decimalPart.slice(0, 3);

    // Pad the decimalPart with zeros to ensure it has exactly 3 digits
    decimalPart = decimalPart.padEnd(3, "0");

    // Combine integer and decimal parts with a colon
    var result = integerPart + ":" + decimalPart;

    // Set the value property of rounded
    rounded.value = result;
  }
};

// To get random color generated
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};


 export function formatNumberWithCommas(number) {

  // Ensure the number is a finite number
  if (isNaN(number) || !Number.isFinite(number)) return '0.00';
  
  // Convert the number to a string with 2 decimal places
  const [integerPart, decimalPart] = number.toFixed(2).split('.');
  // Add commas to the integer part
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Return the formatted number with the decimal part
  return `${formattedIntegerPart}.${decimalPart}`;
}
