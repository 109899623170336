import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
  Typography,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import LoadingData from "../../components/Loaders/Loading";
import { ThemeProvider } from "@emotion/react";
import { useTheme } from "@mui/material/styles";

function WithdrawalRequests() {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [withdrawalRequests, setWithdrawalRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFullAddress, setShowFullAddress] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [coins, setCoins] = useState([]);
  const [coinFilter, setCoinFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortCriteria, setSortCriteria] = useState("createdAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    const fetchWithdrawalRequests = async () => {
      try {
        const response = await apiServiceFetchers.getAllWithdrawalRequests(
          currentUser.token
        );
        setWithdrawalRequests(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    const fetchCoins = async () => {
      try {
        const response = await apiServiceFetchers.getAllCoins(
          currentUser.token
        );
        setCoins(response.coins);
      } catch (error) {}
    };

    fetchWithdrawalRequests();
    fetchCoins();
  }, [currentUser.token]);

  const handleApproveRequest = async (request) => {
    try {
      const wallets = await apiServiceFetchers.getAllUserWalletBalances(
        request.userId,
        currentUser.token
      );
      const wallet = wallets.find((wallet) => wallet.coinId === request.coinId);

      if (!wallet || wallet.balance < request.amount) {
        setSnackbar({
          open: true,
          message: "Insufficient balance to approve the withdrawal request",
          severity: "error",
        });
        return;
      }

      setWithdrawalRequests(
        withdrawalRequests.map((req) =>
          req.id === request.id ? { ...req, status: "approved" } : req
        )
      );

      setSnackbar({
        open: true,
        message: "Withdrawal request approved successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error approving withdrawal request",
        severity: "error",
      });
    }
  };

  const handleRejectRequest = async (id) => {
    try {
      await apiServiceUpdate.rejectWithdrawalRequest(id, currentUser.token);
      setWithdrawalRequests(
        withdrawalRequests.map((request) =>
          request.id === id ? { ...request, status: "rejected" } : request
        )
      );
      setSnackbar({
        open: true,
        message: "Withdrawal request rejected successfully",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error rejecting withdrawal request",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "coin") setCoinFilter(value);
    if (name === "status") setStatusFilter(value);
  };

  const handleSortChange = (e) => {
    setSortCriteria(e.target.value);
  };

  const handleAddressClick = () => {
    setShowFullAddress(!showFullAddress);
  };

  const truncateAddress = (address, length) => {
    if (address.length > length) {
      return address.substring(0, length) + "...";
    }
    return address;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRequests = withdrawalRequests
    .filter((request) => (coinFilter ? request.coinId === coinFilter : true))
    .filter((request) =>
      statusFilter ? request.status === statusFilter : true
    )
    .sort((a, b) => {
      if (sortCriteria === "createdAt")
        return new Date(b.createdAt) - new Date(a.createdAt);
      if (sortCriteria === "amount") return a.amount - b.amount;
      if (sortCriteria === "status") return a.status.localeCompare(b.status);
      return 0;
    });

  const paginatedRequests = filteredRequests.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const renderStatusCell = (status) => {
    let backgroundColor;
    switch (status) {
      case "pending":
        backgroundColor = theme.palette.alert.disabled;
        break;
      case "approved":
        backgroundColor = "green";
        break;
      case "rejected":
        backgroundColor = theme.palette.alert.warning;
        break;
      default:
        backgroundColor = "transparent";
        break;
    }
    return (
      <TableCell
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor,
          padding: "4px",
          borderRadius: "4px",
          color: "#fff",
          width: "100%",
          opacity: "0.7",
          fontSize: {
            xs: "1rem",
            sm: "0.9rem",
            lg: "0.9rem",
          },
        }}
      >
        {status}
      </TableCell>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {loading ? (
        <Grid
          paddingTop={2}
          container
          spacing={2}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoadingData />
        </Grid>
      ) : (
        <Box>
          <Typography
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "0.8rem",
                lg: "0.9rem",
              },
            }}
          >
            Withdrawal Requests
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            mb={2}
            width={700}
            sx={{
              fontSize: {
                xs: "1rem",
                sm: "0.8rem",
                lg: "0.9rem",
              },
            }}
          >
            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Coin</InputLabel>
              <Select
                name="coin"
                value={coinFilter}
                onChange={handleFilterChange}
                label="Coin"
                sx={{ fontSize: { xs: "1rem", sm: "0.8rem", lg: "0.9rem" } }}
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {coins.map((coin) => (
                  <MenuItem key={coin.id} value={coin.coin_id}>
                    {coin.coin_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={statusFilter}
                onChange={handleFilterChange}
                label="Status"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="rejected">Rejected</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ minWidth: 200 }}>
              <InputLabel>Sort By</InputLabel>
              <Select
                value={sortCriteria}
                onChange={handleSortChange}
                label="Sort By"
              >
                <MenuItem value="createdAt">Date</MenuItem>
                <MenuItem value="amount">Amount</MenuItem>
                <MenuItem value="status">Status</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ maxWidth: "100px" }}>ID</TableCell>
                  <TableCell sx={{ maxWidth: "150px" }}>User ID</TableCell>
                  <TableCell sx={{ maxWidth: "100px" }}>Coin Id</TableCell>
                  <TableCell sx={{ maxWidth: "200px" }}>
                    Transaction Kind
                  </TableCell>
                  <TableCell sx={{ maxWidth: "100px" }}>Status</TableCell>
                  <TableCell sx={{ maxWidth: "100px" }}>Amount</TableCell>
                  <TableCell sx={{ maxWidth: "300px" }}>
                    Destination Address
                  </TableCell>
                  <TableCell sx={{ maxWidth: "200px" }}>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRequests.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedRequests.map((request) => (
                    <TableRow
                      key={request.id}
                      sx={{
                        "& .MuiDataGrid-cell": {
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.8rem",
                            lg: "1rem",
                          },
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                          fontSize: {
                            xs: "0.85rem",
                            sm: "0.8rem",
                            lg: "1rem",
                          },
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {request.id.split("-")[0].toUpperCase()}
                      </TableCell>
                      <TableCell
                        sx={{
                          minWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {request.userId}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {request.coinId}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {request.transactionKind}
                      </TableCell>
                      {renderStatusCell(request.status)}
                      <TableCell
                        sx={{
                          maxWidth: "100px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {(() => {
                          if (!request.amount) return "";

                          const amount = parseFloat(request.amount);

                          if (isNaN(amount) || !isFinite(amount))
                            return "Error";

                          // Find the matching coin from Redux store
                          const matchingCoin = coins.find((coin) => {
                            const coinId = coin.coin_id?.trim().toLowerCase();
                            const transactionKind = request.transaction_kind
                              ?.trim()
                              .toLowerCase();
                            return coinId === transactionKind;
                          });

                          // Set decimal points based on matched coin
                          const minDecimals =
                            matchingCoin?.minimum_decimal_point ?? 2;
                          const maxDecimals =
                            matchingCoin?.maximum_decimal_point ?? 8;

                          // Properly format amount
                          const formattedAmount = amount.toLocaleString(
                            "en-US",
                            {
                              minimumFractionDigits: minDecimals,
                              maximumFractionDigits: maxDecimals,
                            }
                          );

                          return formattedAmount;
                        })()}
                      </TableCell>

                      <TableCell
                        onClick={handleAddressClick}
                        style={{ cursor: "pointer", width: "10%" }}
                        sx={{
                          maxWidth: "300px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {showFullAddress
                          ? request.destinationAddress
                          : truncateAddress(request.destinationAddress, 15)}
                      </TableCell>
                      <TableCell
                        sx={{
                          maxWidth: "200px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: {
                            xs: "1rem",
                            sm: "0.9rem",
                            lg: "0.9rem",
                          },
                        }}
                      >
                        {new Date(request.createdAt).toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        })}
                      </TableCell>
                      <TableCell sx={{ display: "flex", gap: 1 }}>
                        <Button
                          variant="outlined"
                          sx={{
                            color: theme.palette.text.primary,
                            height: "30px",
                            backgroundColor: theme.palette.button.primary,
                            fontSize: {
                              xs: "1rem",
                              sm: "0.9rem",
                              lg: "0.9rem",
                            },
                            "&:hover": {
                              borderColor: theme.palette.text.primary,
                            },
                          }}
                          onClick={() => handleApproveRequest(request)}
                          disabled={request.status !== "pending"}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{
                            borderColor: theme.palette.text.primary,
                            color: theme.palette.text.primary,
                            opacity: "0.7",
                            height: "30px",
                            fontSize: {
                              xs: "1rem",
                              sm: "0.8rem",
                              lg: "0.8rem",
                            },
                            backgroundColor: theme.palette.alert.warning,
                            "&:hover": {
                              borderColor: theme.palette.text.primary,
                              color: theme.palette.text.primary,
                            },
                          }}
                          onClick={() => handleRejectRequest(request.id)}
                          disabled={request.status !== "pending"}
                        >
                          Reject
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              sx={{
                width: "100%",
                backgroundColor:
                  snackbar.severity === "error"
                    ? "red"
                    : theme.palette.button.primary,
              }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default WithdrawalRequests;
