import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Snackbar,
  Card,
  Button,
  TableCell,
  Box,
  Grid,
  InputBase,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { AuthContext } from "../../Auth/AuthContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { tableCellClasses } from "@mui/material/TableCell";
import apiService from "../../services/apiServiceFetchers";
import { handleApiError } from "../../constants/helpers";
import LoadingData from "../Loaders/Loading";
import { fetchCoinsOnly } from "../../redux/actions/coinsActions";

// Custom styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: { xs: "0.85rem", sm: "0.8rem", md: "0.65rem", lg: "0.7" }, // Increased font size for responsiveness
  },
}));

// Custom styled components
const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.alert.disabled,
  margin: theme.spacing(2),
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const SearchIconWrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: "40px",
  flex: 1,
  "& input": {
    padding: "10px 12px",
    width: "100%",
  },
}));

const ClearIconWrapper = styled(IconButton)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function CustomToolbar({ searchText, setSearchText }) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
            >
              <ClearIcon />
            </ClearIconWrapper>
          )}
        </Search>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: {
              xs: "1rem",
              sm: "0.8rem",
              lg: "0.9rem",
            },
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButtonBase-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-label": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <GridToolbar />
        </GridToolbarContainer>
      </Box>
      <Divider />
    </ThemeProvider>
  );
}

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 6,
}));

const Transactions = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const coins = useSelector((state) => state.coins.allCoins || []);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await apiService.getTransactions(currentUser.token);
        setTransactions(response.transactions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error, setOpenSnackbar, setSnackbarMessage);
      }
    };
    fetchTransactions();
  }, [currentUser.token]);

  // to format the bances to various decimals
  useEffect(() => {
    if (currentUser.token) {
      dispatch(fetchCoinsOnly(currentUser.token));
    }
  }, [dispatch, currentUser.token]);

  const filterData = (data, searchText) => {
    return data.filter(
      (transaction) =>
        transaction.transaction_type
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        transaction.id.toLowerCase().includes(searchText.toLowerCase()) ||
        transaction.transaction_kind
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        transaction.transaction_status
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );
  };

  const filteredData = filterData(transactions, searchText);

  const formatDate = (date) => {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";

    return parsedDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const handleViewTransaction = async (transactionId) => {
    try {
      const response = await apiService.getTransactionsByReference(
        currentUser.token,
        transactionId
      );
      setSelectedTransaction(response);
      setOpenPopup(true);
    } catch (error) {
      handleApiError(error, setOpenSnackbar, setSnackbarMessage);
    }
  };

  // to be able to copy
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setSnackbarMessage("Copied to clipboard!");
        setOpenSnackbar(true);
      },
      (err) => {
        setSnackbarMessage("Failed to copy text.");
        setOpenSnackbar(true);
      }
    );
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 200,
      renderCell: (params) => {
        const truncatedId = params.value.split("-")[0].toUpperCase();
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {truncatedId}
          </Box>
        );
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => (
        <Tooltip title={formatDate(params.value)} arrow>
          <Box
            p={1}
            sx={{
              display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {formatDate(params.value)}
          </Box>
        </Tooltip>
      ),
    },
    {
      field: "transaction_kind",
      headerName: "Coin",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => params.value?.toUpperCase(),
    },
    {
      field: "pay_coin_amount",
      headerName: "Transaction Amount",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" - "}
            </Box>
          );
        }
    
        const amount = parseFloat(params.value);
    
        if (isNaN(amount) || !isFinite(amount)) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              Error
            </Box>
          );
        }
    
        // Find the matching coin from the Redux store
        const matchingCoin = coins.find((coin) => {
          const coinId = coin.coin_id?.trim().toLowerCase();
          const transactionKind = params.row.transaction_kind
            ?.trim()
            .toLowerCase();
          return coinId === transactionKind;
        });
    
        // Set decimal points based on matched coin
        const minDecimals = matchingCoin?.minimum_decimal_point ?? 2;
        const maxDecimals = matchingCoin?.maximum_decimal_point ?? 8;
        
        // Properly format amount
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: minDecimals,
          maximumFractionDigits: maxDecimals,
        });
        
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formattedAmount}
          </Box>
        );
      },
    },
    {
      field: "transaction_status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        let backgroundColor;
        switch (params.value) {
          case "pending":
            backgroundColor = theme.palette.alert.disabled;
            break;
          case "complete":
            backgroundColor = "green";
            break;
          case "rejected":
            backgroundColor = "red";
            break;
          default:
            backgroundColor = "transparent";
            break;
        }
        return (
          <span
            className="status"
            style={{
              display: "flex",
              opacity: "0.7",
              justifyContent: "center",
              alignContent: "center",
              backgroundColor,
              padding: "5px",
              borderRadius: "4px",
              color: "#fff",
              width: "100px",
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <StyledTableCell>
          <StyledButton
            startIcon={<VisibilityIcon />}
            onClick={() => handleViewTransaction(params.row.id)}
            sx={{
              fontSize: {
                xs: "1rem", // Font size for extra small screens
                sm: "0.8rem", // Font size for small screens
                lg: "0.9rem",
              },
            }}
          >
            View Transaction
          </StyledButton>
        </StyledTableCell>
      ),
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box p={3} elevation={2}>
        {loading ? (
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <LoadingData />
          </Grid>
        ) : filteredData.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              gap: 6, // Add gap between children
            }}
          >
            <Typography variant="h5" mb={2}>
              No Transaction found
            </Typography>
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
              sx={{ marginBottom: 2 }}
            >
              <ClearIcon />
            </ClearIconWrapper>
          </Box>
        ) : (
          <Card>
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <Box sx={{ minWidth: 900 }}>
                <DataGrid
                  rows={filteredData}
                  columns={columns}
                  autoHeight
                  components={{ Toolbar: CustomToolbar }}
                  componentsProps={{
                    toolbar: { searchText, setSearchText },
                  }}
                  sx={{
                    "& .MuiDataGrid-cell": {
                      fontSize: {
                        xs: "1rem",
                        sm: "0.8rem",
                        lg: "0.9rem",
                      },
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontSize: {
                        xs: "1rem",
                        sm: "0.8rem",
                        lg: "0.9rem",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Card>
        )}
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
      {/* Popup */}
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>Transaction Details</DialogTitle>
        <Divider />
        <DialogContent>
          {selectedTransaction ? (
            <>
              <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
                Transaction Info
              </Typography>
              <Divider />
              <Typography>
                Coin:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction.transactionKind.toUpperCase()}
                </span>
              </Typography>
              <Typography>
                Transaction Id:{" "}
                <Tooltip title="Copy to clipboard">
                  <span
                    style={{
                      color: theme.palette.text.secondary,
                      cursor: "pointer",
                    }}
                    onClick={() => copyToClipboard(selectedTransaction?.id)}
                  >
                    {selectedTransaction?.id}
                  </span>
                </Tooltip>
              </Typography>
              {/* the track url for txn hash */}
              {selectedTransaction.trackUrl &&
                (selectedTransaction?.transactionType === "deposit" ||
                  selectedTransaction?.transactionType === "withdrawal") && (
                  <Typography>
                    Transaction Hash:{" "}
                    {selectedTransaction.trackUrl ? (
                      (() => {
                        const hash = selectedTransaction.trackUrl
                          .split("/")
                          .pop()
                          .toLowerCase();
                        return (
                          <Button
                            onClick={() =>
                              window.open(
                                selectedTransaction.trackUrl,
                                "_blank",
                                "noopener,noreferrer"
                              )
                            }
                            style={{
                              color: theme.palette.primary.main,
                              textDecoration: "none",
                              padding: 0,
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "330px",
                              display: "inline-block",
                              fontSize: "inherit",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {hash}
                          </Button>
                        );
                      })()
                    ) : (
                      <span style={{ color: theme.palette.text.secondary }}>
                        Not available
                      </span>
                    )}
                  </Typography>
                )}

              <Typography>
                Transaction Type:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction.transactionType}
                </span>
              </Typography>
              <Typography>
                Transaction Amount:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction.payCoinAmount}
                </span>
              </Typography>
              {(selectedTransaction.transactionType === "buy" ||
                selectedTransaction.transactionType === "sell") && (
                <Typography>
                  Transaction Fee:{" "}
                  <span style={{ color: theme.palette.text.secondary }}>
                    {selectedTransaction.transactionChargeAmount}
                  </span>
                </Typography>
              )}

              {/* <Typography>
                Transaction Charge Type:
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction.transactionChargeType}
                </span>
              </Typography> */}
              <Typography>
                Transaction Reference:{" "}
                <Tooltip title="Copy to clipboard">
                  <span
                    style={{
                      color: theme.palette.text.secondary,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      copyToClipboard(selectedTransaction?.transactionReference)
                    }
                  >
                    {selectedTransaction?.transactionReference}
                  </span>
                </Tooltip>
              </Typography>
              <Typography>
                Source Address:{" "}
                <Tooltip title="Copy to clipboard">
                  <span
                    style={{
                      color: theme.palette.text.secondary,
                      cursor: "pointer",
                      wordBreak: "break-all", // Breaks long words
                      whiteSpace: "normal", // Allows wrapping
                      display: "inline-block", // Ensures proper wrapping
                      maxWidth: "100%", // Adjusts to container width
                    }}
                    onClick={() =>
                      copyToClipboard(selectedTransaction?.sourceAddress)
                    }
                  >
                    {selectedTransaction?.sourceAddress}
                  </span>
                </Tooltip>
              </Typography>

              <Typography>
                Created At:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {formatDate(selectedTransaction.createdAt)}
                </span>
              </Typography>
              <Typography>
                Status:{" "}
                <span
                  style={{
                    color:
                      selectedTransaction.transactionStatus === "complete"
                        ? "green"
                        : selectedTransaction.transactionStatus === "pending"
                        ? "red"
                        : "inherit",
                  }}
                >
                  {selectedTransaction.transactionStatus}
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 16, marginTop: 1 }}
              >
                Sender
              </Typography>
              <Divider />
              <Typography>
                FullName:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.sender?.details?.fullName || " - "}
                </span>
              </Typography>
              <Typography>
                Email:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.sender?.details?.email || " - "}
                </span>
              </Typography>
              <Typography>
                Phone Number:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.sender?.details?.phoneNumber || " - "}
                </span>
              </Typography>
              <Typography
                sx={{ fontWeight: "bold", fontSize: 16, marginTop: 1 }}
              >
                Receiver
              </Typography>
              <Divider />

              <Typography>
                FullName:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.receiver?.details?.fullName || " - "}
                </span>
              </Typography>
              <Typography>
                Email:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.receiver?.details?.email || " - "}
                </span>
              </Typography>
              <Typography>
                Phone Number:{" "}
                <span style={{ color: theme.palette.text.secondary }}>
                  {selectedTransaction?.receiver?.details?.phoneNumber || " - "}
                </span>
              </Typography>
            </>
          ) : (
            <Typography>No transaction details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenPopup(false)}
            variant="outlined"
            sx={{
              marginBottom: 2,
              color: theme.palette.text.primary,
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.button.primary,
              "&:hover": {
                backgroundColor: theme.palette.button.secondary,
                borderColor: theme.palette.background.paper,
                transform: "scale(0.95)",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default Transactions;
