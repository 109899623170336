import React, { createContext, useEffect, useReducer, useState } from "react";
import AuthReducer from "./AuthReducer";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography
} from "@mui/material";

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  token: localStorage.getItem("token") || null,
  graphData: JSON.parse(localStorage.getItem("graphData")) || null,
  balanceData: JSON.parse(localStorage.getItem("balanceData")) || []
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
    localStorage.setItem("token", state.token);
    localStorage.setItem("graphData", JSON.stringify(state.graphData));
    localStorage.setItem("balanceData", JSON.stringify(state.balanceData));

    const checkTokenExpiration = () => {
      const tokenExpiration = localStorage.getItem("tokenExpiration");
      if (tokenExpiration) {
        const expirationTime = parseInt(tokenExpiration, 10);
        const currentTime = new Date().getTime();
        if (currentTime > expirationTime) {
          dispatch({ type: "LOGOUT" });
          setOpenPopup(true);
        }
      }
    };

    const interval = setInterval(checkTokenExpiration, 1000); // Check every second
    return () => clearInterval(interval);
  }, [
    state.currentUser,
    state.token,
    state.graphData,
    state.balanceData,
    dispatch
  ]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
      <Dialog open={openPopup}>
        <DialogTitle>Login Expired</DialogTitle>
        <DialogContent>
          <Typography>Your login has expired. Please log in again.</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Button
            onClick={() => {
              setOpenPopup(false);
              // Redirect to login page
              window.location.href = "/login";
            }}
            variant="outlined"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </AuthContext.Provider>
  );
};

export default AuthContext;
