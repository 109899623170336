import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  Box,
  Container,
  Typography,
  ThemeProvider,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceActions from "../../services/apiServiceActions";

export default function AddCoin() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [coinName, setCoinName] = useState("");
  const [coinId, setCoinId] = useState("");
  const [fireblocksAssetId, setFireblocksAssetId] = useState("");
  const [iconStyle, setIconStyle] = useState("");
  const [standardPrice, setStandardPrice] = useState("1");
  const [minimumDecimalPoint, setMinimumDecimalPoint] = useState(2);
  const [maximumDecimalPoint, setMaximumDecimalPoint] = useState(2);
  const [withdrawalCommission, setWithdrawalCommission] = useState(1);
  const [oneOffWithdrawalLimit, setOneOffWithdrawalLimit] = useState(10000);
  const [dailyWithdrawalLimit, setDailyWithdrawalLimit] = useState(10000);
  const [transactionFee, setTransactionFee] = useState(0.1);
  const [adminApprovalAmount, setAdminApprovalAmount] = useState(10000);
  const [minimumWithdrawalAmount, setMinimumWithdrawalAmount] = useState(1);
  const [minimumTransferAmount, setMinimumTransferAmount] = useState(3);
  const [transferFee, setTransferFee] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [price, setPrice] = useState(0); // Added price state
  const [description, setDescription] = useState(""); // Added description state
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    // Client-side validation
    if (
      !coinName ||
      !coinId ||
      !fireblocksAssetId ||
      !iconStyle ||
      !description
    ) {
      setSnackbarMessage("Please fill in all required fields.");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
      return;
    }

    if (isNaN(Number(price)) || Number(price) < 0) {
      setSnackbarMessage("Price must be a non-negative number.");
      setSnackbarSeverity("warning");
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);
    const coinData = {
      coinId,
      coinName,
      coinLabel: coinId,
      price: Number(price),
      description,
      iconStyle,
      standardPrice: Number(standardPrice),
      minimumDecimalPoint,
      maximumDecimalPoint,
      withdrawalCommission,
      oneOffWithdrawalLimit,
      dailyWithdrawalLimit,
      transactionFee,
      adminApprovalAmount,
      minimumWithdrawalAmount,
      minimumTransferAmount,
      transferFee,
      isActive,
      fireblocksAssetId,
    };

    try {
      await apiServiceActions.createCoin(currentUser.token, coinData);
      setSnackbarMessage("Coin added successfully!");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
      setTimeout(() => navigate("/coins"), 2000);
    } catch (error) {
      let errorMessage = "Failed to create coin. Please try again.";

      if (error.response) {

        // Extracting backend error message
        if (error.response.data?.error) {
          errorMessage = `Failed to create coin: ${error.response.data.error}`;
        } else if (error.response.data?.message) {
          errorMessage = `Failed to create coin: ${error.response.data.message}`;
        } else {
          errorMessage = `Failed to create coin: ${error.response.statusText} (Status: ${error.response.status})`;
        }

        // Handling validation errors
        if (
          error.response.status === 400 &&
          error.response.data?.errors
        ) {
          errorMessage = `Validation Errors: ${JSON.stringify(error.response.data.errors)}`;
        }
      } else if (error.message) {
        errorMessage = `Network error: ${error.message}`;
      }

      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          p={3}
          sx={{
            backgroundColor: theme.palette.background.paper,
            width: "100%",
            boxShadow: 1,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Register New Coin
          </Typography>
          <TextField
            label="Coin Name"
            placeholder="Enter coin name (e.g., Bitcoin)"
            fullWidth
            margin="normal"
            value={coinName}
            onChange={(e) => setCoinName(e.target.value)}
            required
          />
          <TextField
            label="Coin ID"
            placeholder="Enter coin ID (e.g., BTC)"
            fullWidth
            margin="normal"
            value={coinId}
            onChange={(e) => setCoinId(e.target.value)}
            required
          />
          <TextField
            label="Fireblocks Asset ID"
            placeholder="Enter Fireblocks Asset ID"
            fullWidth
            margin="normal"
            value={fireblocksAssetId}
            onChange={(e) => setFireblocksAssetId(e.target.value)}
            required
          />

          <TextField
            label="Icon Style URL"
            placeholder="Enter icon URL(e.g., https://s2.coinmarketcap.com/static/img/coins/64x64/825.png"
            fullWidth
            margin="normal"
            value={iconStyle}
            onChange={(e) => setIconStyle(e.target.value)}
            required
          />
          <TextField
            label="Standard Price"
            placeholder="Enter standard price"
            fullWidth
            margin="normal"
            value={standardPrice}
            onChange={(e) => setStandardPrice(e.target.value)}
          />
          <TextField
            label="Min Decimal Point"
            placeholder="Enter minimum decimal points (e.g., 2)"
            type="number"
            fullWidth
            margin="normal"
            value={minimumDecimalPoint}
            onChange={(e) => setMinimumDecimalPoint(Number(e.target.value))}
          />
          <TextField
            label="Max Decimal Point"
            placeholder="Enter maximum decimal points (e.g., 8)"
            type="number"
            fullWidth
            margin="normal"
            value={maximumDecimalPoint}
            onChange={(e) => setMaximumDecimalPoint(Number(e.target.value))}
          />
          <TextField
            label="Withdrawal Commission %"
            placeholder="Enter withdrawal commission (e.g., 1%)"
            fullWidth
            margin="normal"
            value={withdrawalCommission }
            onChange={(e) => setWithdrawalCommission(Number(e.target.value))}
          />
          <TextField
            label="One-Off Withdrawal Limit"
            placeholder="Enter one-time withdrawal limit"
            fullWidth
            margin="normal"
            value={oneOffWithdrawalLimit}
            onChange={(e) => setOneOffWithdrawalLimit(Number(e.target.value))}
          />
          <TextField
            label="Daily Withdrawal Limit"
            placeholder="Enter daily withdrawal limit"
            fullWidth
            margin="normal"
            value={dailyWithdrawalLimit}
            onChange={(e) => setDailyWithdrawalLimit(Number(e.target.value))}
          />
          <TextField
            label="Transaction Fee"
            placeholder="Enter transaction fee (e.g., 0.1%)"
            fullWidth
            margin="normal"
            value={transactionFee}
            onChange={(e) => setTransactionFee(Number(e.target.value))}
          />
          <TextField
            label="Admin Approval Amount"
            placeholder="Enter amount requiring admin approval"
            fullWidth
            margin="normal"
            value={adminApprovalAmount}
            onChange={(e) => setAdminApprovalAmount(Number(e.target.value))}
          />
          <TextField
            label="Minimum Withdrawal Amount"
            placeholder="Enter minimum withdrawal amount"
            fullWidth
            margin="normal"
            value={minimumWithdrawalAmount}
            onChange={(e) => setMinimumWithdrawalAmount(Number(e.target.value))}
          />
          <TextField
            label="Minimum Transfer Amount"
            placeholder="Enter minimum transfer amount"
            fullWidth
            margin="normal"
            value={minimumTransferAmount}
            onChange={(e) => setMinimumTransferAmount(Number(e.target.value))}
          />
          <TextField
            label="Transfer Fee"
            placeholder="Enter transfer fee"
            fullWidth
            margin="normal"
            value={transferFee}
            onChange={(e) => setTransferFee(Number(e.target.value))}
          />
          <TextField
            label="Price (USD)"
            placeholder="Enter price in USD"
            type="number"
            fullWidth
            margin="normal"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
          <TextField
            label="Description"
            placeholder="Enter coin description"
            fullWidth
            margin="normal"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                name="isActive"
                color="primary"
              />
            }
            label="Is Active"
            sx={{ display: "block", textAlign: "left", marginLeft: 0 }}
          />
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            sx={{ mt: 2 }}
            disabled={loading} // Disable button while loading
          >
            {loading ? "Loading..." : `Add ${coinName} Coin`}
          </Button>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={5000} // Increased duration to 5 seconds
          severity={snackbarSeverity}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />
      </Container>
    </ThemeProvider>
  );
}
