import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../Auth/AuthContext";
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  Card,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TablePagination,
  Divider,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailIcon from "@mui/icons-material/Email";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTheme } from "@mui/material/styles";
import { fetchNotifications } from "../../redux/actions/notificationActions";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import LoadingData from "../../components/Loaders/Loading";
import { useMediaQuery } from "@mui/material";

const ITEM_HEIGHT = 48;

const Notifications = () => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const { showNotifications, loadingNotifications, error } = useSelector(
    (state) => state.notifications
  );
  const [notifications, setNotifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedNotification, setExpandedNotification] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  useEffect(() => {
    if (showNotifications) {
      setNotifications(showNotifications);
    }
  }, [showNotifications]); // <-- Add useEffect here

  useEffect(() => {
    if (currentUser.token) {
      dispatch(fetchNotifications(currentUser.token));
    }
  }, [dispatch, currentUser.token]);

  const handleExpand = async (notification) => {
    setExpandedNotification((prev) =>
      prev === notification.id ? null : notification.id
    );
    if (!notification.isRead) {
      try {
        await apiServiceUpdate.updateNotificationAsRead(
          notification.id,
          { isRead: true },
          currentUser.token
        );
        setNotifications((prevNotifications) =>
          [...prevNotifications]
            .map((n) => (n.id === notification.id ? { ...n, isRead: true } : n))
            .sort((a, b) =>
              a.isRead === b.isRead
                ? new Date(b.updatedAt) - new Date(a.updatedAt)
                : a.isRead - b.isRead
            )
        );
      } catch (error) {
        // Handle error
      }
    }
  };

  const handleMarkAsRead = async () => {
    if (expandedNotification) {
      try {
        await apiServiceUpdate.updateNotificationAsRead(
          expandedNotification,
          { isRead: true },
          currentUser.token
        );
        setNotifications((prevNotifications) =>
          [...prevNotifications]
            .map((n) =>
              n.id === expandedNotification ? { ...n, isRead: true } : n
            )
            .sort((a, b) =>
              a.isRead === b.isRead
                ? new Date(b.updatedAt) - new Date(a.updatedAt)
                : a.isRead - b.isRead
            )
        );
        setExpandedNotification(null);
        setAnchorEl(null);
      } catch (error) {
        // Handle error
      }
    }
  };

  const formatDate = (date) => {
    if (!date) return "";

    const parsedDate = new Date(date);
    if (isNaN(parsedDate.getTime())) return "";

    return parsedDate.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  if (loadingNotifications) return <LoadingData />;
  if (error) return <p>Error: {error}</p>;

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate paginated notifications
  const paginatedNotifications = notifications.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box
      p={isMobile ? 1 : isTablet ? 2 : 3}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <>
        <Typography
          sx={{
            padding: 1,
            fontWeight: "bold",
            fontSize: theme.typography.responsiveFontSizes,
          }}
          gutterBottom
        >
          Notifications
        </Typography>
        <Divider mb={1} />

        {loadingNotifications ? (
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <LoadingData />
          </Grid>
        ) : (
          <Box>
            {paginatedNotifications.length === 0 ? (
              <Typography
                variant="subtitle1"
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                No notifications found
              </Typography>
            ) : (
              paginatedNotifications.map((notification) => (
                <Card
                  variant="outlined"
                  sx={{
                    marginBottom: 2,
                    width: { xs: "95%" },
                    padding: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.text.primary,
                  }}
                  key={notification.id}
                  onClick={() => handleExpand(notification)}
                >
                  <Grid container alignItems="center">
                    <Avatar
                      sx={{
                        bgcolor: theme.palette.text.primary,
                        width: isMobile ? 40 : 56,
                        height: isMobile ? 40 : 56,
                        marginRight: isMobile ? 2 : 5,
                      }}
                      alt={notification.type}
                      src={notification.type ? notification.type[0] : ""}
                    />
                    <Grid item xs={8}>
                      <Typography
                        sx={{
                          fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
                        }}
                      >
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: {
                              xs: "1rem",
                              md: "0.9rem",
                              lg: "0.8rem",
                            },
                          }}
                        >
                          Notification type:
                        </span>{" "}
                        {notification.type}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: "1rem", md: "0.9rem", lg: "0.8rem" },
                        }}
                        noWrap={
                          !expandedNotification ||
                          expandedNotification !== notification.id
                        }
                      >
                        {notification.message}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      container
                      alignItems="center"
                      justifyContent="flex-end"
                      sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: {
                            xs: "1rem",
                            md: "0.9rem",
                            lg: "0.8rem",
                          },
                        }}
                      >
                        {formatDate(notification.updatedAt)}
                      </Typography>
                      {/* Message Icon and More Icon in the same row */}
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {/* Tooltip with Message Icon */}
                        <Tooltip
                          title={notification.isRead ? "Read" : "Unread"}
                        >
                          <IconButton>
                            {notification.isRead ? (
                              <DraftsIcon />
                            ) : (
                              <EmailIcon />
                            )}
                          </IconButton>
                        </Tooltip>

                        {/* Three Dots More Icon */}
                        <IconButton
                          aria-label="more"
                          aria-controls={anchorEl ? "long-menu" : undefined}
                          aria-expanded={anchorEl ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(event) => {
                            event.stopPropagation();
                            setAnchorEl(event.currentTarget);
                            setExpandedNotification(notification.id);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </Stack>

                      {/* Menu for More Icon */}
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem onClick={handleMarkAsRead}>
                          Mark as read
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                </Card>
              ))
            )}
          </Box>
        )}

        <TablePagination
          component="div"
          count={showNotifications.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </>
    </Box>
  );
};

export default Notifications;
