import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import apiServiceUpdate from "../../services/apiServiceUpdate";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  Avatar,
  Tooltip,
  Button,
  Modal,
  Snackbar,
  Stack,
  Divider,
  Switch,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { ThemeProvider } from "@emotion/react";
import LoadingData from "../../components/Loaders/Loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoinsAndBalances } from "../../redux/actions/coinsActions";
import ClearIcon from "@mui/icons-material/Clear";

const Coins = () => {
  const { currentUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const cachedCoinsWithBalances = useSelector(
    (state) => state.coins.cachedCoinsWithBalances
  );
  const loadingCoins = useSelector((state) => state.coins.loadingCoins);
  const error = useSelector((state) => state.coins.error);
  const [openModal, setOpenModal] = useState(false);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [editedFields, setEditedFields] = useState({});
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    coinId: null,
    newStatus: null,
  });

  // Search query state
  const [searchQuery, setSearchQuery] = useState("");

  const { vertical, horizontal, open } = snackbarState;
  // FOR WALLETS
  const [wallets, setWallets] = useState([]);

  const theme = useTheme();

  // Dispatch the action to fetch coins and balances when the component mounts
  useEffect(() => {
    if (!cachedCoinsWithBalances || !cachedCoinsWithBalances.length) {
      dispatch(fetchCoinsAndBalances(currentUser.token));
    }
  }, [dispatch, currentUser.token, cachedCoinsWithBalances]);

  // TO FETCH THE COIN ADDRESSES
  useEffect(() => {
    const fetchWallets = async () => {
      try {
        const response = await apiServiceFetchers.getOwnerWallets(
          currentUser.token
        );
        if (response) {
          setWallets(
            response.map((wallet) => ({
              coin: wallet.coinId,
              address: wallet.address,
            }))
          );
        }
      } catch (error) {
        throw error;
      }
    };
    fetchWallets();
  }, [currentUser.token]);

  // Handle search input change
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.trim().toLowerCase());
  };

  // Filter coins based on search query
  const filteredCoins = cachedCoinsWithBalances?.sort((a, b) => {
    const aMatches =
      a.coin_id.toLowerCase().includes(searchQuery) ||
      a.coin_name.toLowerCase().includes(searchQuery);
    const bMatches =
      b.coin_id.toLowerCase().includes(searchQuery) ||
      b.coin_name.toLowerCase().includes(searchQuery);

    if (aMatches && !bMatches) return -1;
    if (!aMatches && bMatches) return 1;
    return 0; // Maintain the order for non-matching items
  });

  const handleEdit = async (coinId) => {
    try {
      const fetchedCoin = await apiServiceFetchers.getCoinById(
        coinId,
        currentUser.token
      );
      setSelectedCoin(fetchedCoin);
      setEditedFields({
        coin_id: fetchedCoin.coin_id,
        coin_name: fetchedCoin.coin_name,
        price: fetchedCoin.price,
        description: fetchedCoin.description,
        iconStyle: fetchedCoin.iconStyle,
        withdrawalCommission: fetchedCoin.withdrawal_commission,
        minimumWithdrawalAmount: fetchedCoin.minimum_withdrawal_amount,
        adminApprovalAmount: fetchedCoin.admin_approval_amount,
        oneOffWithdrawalLimit: fetchedCoin.oneOffWithdrawalLimit,
        dailyWithdrawalLimit: fetchedCoin.dailyWithdrawalLimit,
        minimumTransferAmount: fetchedCoin.minimum_transfer_amount,
        transactionFee: fetchedCoin.transactionFee,
        is_active: fetchedCoin.is_active,
      });
      setOpenEditModal(true);
    } catch (error) {
      throw error;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    try {
      // Make the API call to update the coin
      const response = await apiServiceUpdate.updateCoin(
        selectedCoin.id,
        editedFields,
        currentUser.token
      );
      // Check if the update was successful
      if (response.status === 200) {
        // Update the state directly
        fetchCoinsAndBalances();
        // Close the edit modal and clear edited fields
        setOpenEditModal(false);
        setEditedFields({});

        // Show Snackbar notification for successful update
        setSnackbarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: "Coin updated successfully",
          backgroundColor: theme.palette.alert.success,
        });
      } else {
        throw new Error("Failed to update coin");
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Failed to update coin",
        backgroundColor: theme.palette.alert.warning,
      });
      throw error;
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleStatusToggle = (coinId, newStatus) => {
    setConfirmDialog({
      open: true,
      coinId: coinId,
      newStatus: newStatus,
    });
  };

  const handleCancelToggle = () => {
    setConfirmDialog({
      open: false,
      coinId: null,
      newStatus: null,
    });
  };

  // To handle toggle
  const handleConfirmToggle = async () => {
    const { coinId, newStatus } = confirmDialog;
    try {
      await apiServiceUpdate.updateCoinStatus(
        coinId,
        { isActive: newStatus },
        currentUser.token
      );
      // Fetch coins and balances again to ensure the state is up-to-date
      fetchCoinsAndBalances();

      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Coin status updated!",
        backgroundColor: theme.palette.alert.success,
      });
    } catch (error) {
      setSnackbarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Failed to update coin status",
        backgroundColor: theme.palette.alert.warning,
      });
    }

    // Close the confirmation dialog
    setConfirmDialog({
      open: false,
      coinId: null,
      newStatus: null,
    });
  };

  // TO VIEW COIN DETAILS
  const handleViewDetails = (coin) => {
    setSelectedCoin(coin);
    setOpenModal(true);
  };

  const labelText =
    editedFields.coin_id === "KES" ? "Withdrawal Fee" : "Withdrawal Fee (%)";

  const AddressDisplay = ({ address }) => {
    const [showFull, setShowFull] = useState(false);

    return (
      <Tooltip title={showFull ? "" : address} arrow>
        <Typography
          sx={{
            // fontSize: "14px",
            fontWeight: 300,
            color: "text.secondary",
            cursor: "pointer",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // maxWidth: "250px", // Adjust as needed
            // display: "inline-block",
          }}
          onClick={() => setShowFull(!showFull)}
        >
          {showFull ? address : `${address.slice(0, 50)}...`}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box p={3} sx={{ backgroundColor: theme.palette.background.paper }}>
        <Typography
          sx={{
            padding: 1,
            fontWeight: "bold",
            fontSize: theme.typography.responsiveFontSizes,
          }}
          gutterBottom
        >
          Coins
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          // alignItems="flex-start"
          // justifyContent="flex-start"
          sx={{ width: "100%", margin: "auto", mb: 2, mt: 2 }}
        >
          <Button
            variant="outlined"
            sx={{
              fontSize: theme.typography.responsiveFontSizes,
              color: theme.palette.text.primary,
              borderColor: theme.palette.background.paper,
              backgroundColor: theme.palette.button.primary,
              "&:hover": {
                backgroundColor: theme.palette.button.secondary,
                borderColor: theme.palette.background.paper,
                transform: "scale(0.95)",
              },
            }}
            startIcon={<Add />}
            onClick={() => navigate("/add-coin")}
          >
            Add New Coin
          </Button>
          <Box sx={{ width: "60%", display: "flex", justifyContent: "center" }}>
            <TextField
              placeholder="Search coins"
              variant="outlined"
              fullWidth
              sx={{
                maxWidth: "500px",
                "& .MuiOutlinedInput-root": {
                  backgroundColor: theme.palette.background.paper,
                },
                "& .MuiInputLabel-root": {
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.text.secondary,
                },
              }}
              value={searchQuery}
              onChange={handleSearch}
              InputProps={{
                endAdornment: searchQuery && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setSearchQuery("")}
                      edge="end"
                      size="small"
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          ;
        </Stack>

        <Divider />

        {/* Display Filtered Coins */}
        <Box>
          {filteredCoins?.length > 0 ? (
            filteredCoins.map((coin) => (
              <Typography key={coin.coinList?.coinId}>
                {/* ssearch */}
              </Typography>
            ))
          ) : (
            <Typography>No coins found.</Typography>
          )}
        </Box>

        {loadingCoins ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <LoadingData />
          </Box>
        ) : cachedCoinsWithBalances && cachedCoinsWithBalances.length > 0 ? (
          <Grid container spacing={3} xs={12} mt={1}>
            {cachedCoinsWithBalances.map((coin) => {
              // Find matching coin address
              const matchingCoin = wallets.find(
                (item) => item.coin === coin.coin_id
              );

              const matchingAddress = matchingCoin
                ? matchingCoin.address
                : null;

              return (
                <Grid item xs={12} lg={4} key={coin.id}>
                  <Card
                    sx={{
                      border: "1px solid",
                      backgroundColor: theme.palette.background.paper,
                      borderColor: theme.palette.background.paper,
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar
                            src={`${coin.icon_style}`}
                            alt={coin.coin_name}
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              fontWeight: 700,
                              fontSize: theme.typography.responsiveFontSizes,
                            }}
                          >
                            {coin.coin_name} ({coin.coin_id})
                          </Typography>
                          <Typography
                            color="text.secondary"
                            sx={{
                              fontWeight: 700,
                              fontSize: theme.typography.responsiveFontSizes,
                            }}
                          >
                            Fireblocks Asset Id:{" "}
                            {coin?.fireblocks_asset_id &&
                            coin.fireblocks_asset_id.trim().toUpperCase() !==
                              "UNKNOWN"
                              ? coin.fireblocks_asset_id
                              : "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />

                      <Typography
                        sx={{
                          mt: 2,
                          fontSize: theme.typography.responsiveFontSizes,
                        }}
                      >
                        Price:{" "}
                        <span
                          style={{
                            fontWeight: 700,
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {typeof coin.price === "number" && !isNaN(coin.price)
                            ? coin.price.toFixed(8).replace(/\.?0+$/, "") // Removes trailing zeros
                            : "N/A"}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Balance:{" "}
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.balance}{" "}
                        </span>
                      </Typography>
                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Withdrawal Fee:
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.coin_name === "Shilling"
                            ? `${coin.withdrawal_commission}`
                            : `${coin.withdrawal_commission}%`}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Minimum Withdrawal Amount:
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.minimum_withdrawal_amount}{" "}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Minimum Transaction Amount:
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.minimum_transfer_amount}{" "}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        One-off Withdrawal Limit:{" "}
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.oneOffWithdrawalLimit}{" "}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Admin approval amount:{" "}
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.admin_approval_amount}{" "}
                        </span>
                      </Typography>

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Daily Withdrawal Limit:{" "}
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.dailyWithdrawalLimit}{" "}
                        </span>
                      </Typography>
                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Transaction Fee:{" "}
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.transactionFee}%{" "}
                        </span>
                      </Typography>
                      {/* Display Address if Available */}
                      {matchingAddress && (
                        <Typography
                          sx={{
                            fontSize: theme.typography.responsiveFontSizes,
                          }}
                        >
                          Wallet Address:{" "}
                          <AddressDisplay address={matchingAddress} />
                        </Typography>
                      )}

                      <Typography
                        sx={{ fontSize: theme.typography.responsiveFontSizes }}
                      >
                        Status:{" "}
                        <Switch
                          checked={coin.is_active}
                          onChange={() => {
                            handleStatusToggle(coin.id, !coin.is_active); // Toggle status correctly
                          }}
                          color="primary"
                        />
                        <span color="text.secondary" sx={{ fontWeight: 700 }}>
                          {coin.is_active ? "Active" : "Deactivated"}
                        </span>
                      </Typography>

                      <Box sx={{ display: "flex", gap: 2, marginTop: 2 }}>
                        <Button
                          variant="contained"
                          onClick={() => handleViewDetails(coin)}
                          sx={{
                            width: 100,
                            fontSize: theme.typography.responsiveFontSizes,
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.background.paper,
                            backgroundColor: theme.palette.button.primary,
                            "&:hover": {
                              backgroundColor: theme.palette.button.secondary,
                              borderColor: theme.palette.background.paper,
                              transform: "scale(0.95)",
                            },
                          }}
                        >
                          View
                        </Button>

                        <Button
                          variant="contained"
                          onClick={() => handleEdit(coin.id)}
                          sx={{
                            width: 100,
                            fontSize: theme.typography.responsiveFontSizes,
                            color: theme.palette.text.primary,
                            borderColor: theme.palette.background.paper,
                            backgroundColor: theme.palette.button.primary,
                            "&:hover": {
                              backgroundColor: theme.palette.button.secondary,
                              borderColor: theme.palette.background.paper,
                              transform: "scale(0.95)",
                            },
                          }}
                        >
                          Edit
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
            No Coins Found
          </Typography>
        )}
        {error && (
          <Typography
            variant="body1"
            sx={{ textAlign: "center", mt: 2, color: "red" }}
          >
            {error || "An error occurred while fetching coins."}
          </Typography>
        )}
        {/* MODAL TO VIEW THE COIN DETAILS */}
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {selectedCoin && (
              <>
                <Typography variant="h6" gutterBottom>
                  {selectedCoin.coin_name} ({selectedCoin.coin_id})
                </Typography>
                <Typography>Price: ${selectedCoin.price}</Typography>
                <Typography>Balance: {selectedCoin.balance}</Typography>
                <Typography>
                  Withdrawal Fee: {selectedCoin.withdrawal_commission}
                </Typography>
                <Typography>
                  Minimum Withdrawal: {selectedCoin.minimum_withdrawal_amount}
                </Typography>
                <Typography>
                  Transaction Fee: {selectedCoin.transactionFee}%
                </Typography>
                <Typography>
                  One-Off Withdrawal Limit: {selectedCoin.oneOffWithdrawalLimit}
                </Typography>
                <Typography>
                  Daily Withdrawal Limit: {selectedCoin.dailyWithdrawalLimit}
                </Typography>
                <Typography>
                  Admin Approval Amount: {selectedCoin.admin_approval_amount}
                </Typography>
                <Typography>
                  Minimum Transfer Amount:{" "}
                  {selectedCoin.minimum_transfer_amount}
                </Typography>
                <Typography>
                  Transfer Fee: {selectedCoin.transfer_fee}
                </Typography>
                <Typography>
                  Fireblocks Asset ID: {selectedCoin.fireblocks_asset_id}
                </Typography>
                <Typography>Description: {selectedCoin.description}</Typography>

                <Button
                  variant="contained"
                  sx={{
                    fontSize: theme.typography.responsiveFontSizes,
                    color: "white",
                    borderColor: "none",
                    marginTop: 2,
                    opacity: "0.7",
                    backgroundColor: theme.palette.alert.warning,
                    "&:hover": {
                      borderColor: theme.palette.text.primary,
                      color: theme.palette.text.primary,
                      transform: "scale(0.95)",
                    },
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </Button>
              </>
            )}
          </Box>
        </Modal>

        {/* modal  used for the edit button */}
        <Modal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              width: { xs: "80%", lg: "50%" },
              maxHeight: { xs: "90vh", md: "80vh" }, // Restrict height to viewport
              overflowY: "auto", // Enables scrolling inside modal
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "2px solid",
              borderColor: theme.palette.alert.disabled,
              backgroundColor: theme.palette.background.paper,
              boxShadow: 24,
              p: 4,
              display: "flex",
              flexDirection: "column", // Keeps content structured
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Edit {editedFields.coin_name}
            </Typography>
            <Divider />
            <Box id="modal-modal-description" sx={{ mt: 2 }}>
              <TextField
                label="Coin ID"
                name="coin_id"
                value={editedFields.coin_id || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Coin Name"
                name="coin_name"
                value={editedFields.coin_name || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Description"
                name="description"
                value={editedFields.description || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Price"
                name="price"
                type="number"
                value={editedFields.price || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Icon Style"
                type="text"
                name="iconStyle" // Ensure the name is set correctly
                value={editedFields.iconStyle || ""} // Ensure consistency with the key
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label={labelText}
                name="withdrawalCommission"
                type="number"
                value={editedFields.withdrawalCommission}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Minimum Withdrawal Amount"
                name="minimumWithdrawalAmount"
                type="number"
                value={editedFields.minimumWithdrawalAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />

              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Minimum Transaction Amount"
                name="minimumTransferAmount"
                type="number"
                value={editedFields.minimumTransferAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="One-off Withdrawal Limit"
                name="oneOffWithdrawalLimit"
                type="number"
                value={editedFields.oneOffWithdrawalLimit || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Admin approval amount"
                name="adminApprovalAmount"
                type="number"
                value={editedFields.adminApprovalAmount || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{ fontSize: theme.typography.responsiveFontSizes }}
                label="Daily Withdrawal Limit"
                name="dailyWithdrawalLimit"
                type="number"
                value={editedFields.dailyWithdrawalLimit || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                sx={{
                  fontSize: theme.typography.responsiveFontSizes,
                }}
                label="Transaction Fee (%)"
                name="transactionFee"
                type="number"
                value={editedFields.transactionFee || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center", // Centers the buttons horizontally
                gap: 2, // Adds space between buttons
                mt: 2, // Margin-top for spacing
              }}
            >
              <Button
                onClick={handleUpdate}
                variant="outlined"
                sx={{
                  fontSize: {
                    xs: "0.875rem",
                    sm: "0.875rem",
                    md: "1rem",
                    lg: "1.125rem",
                  },
                  color: theme.palette.text.primary,
                  borderColor: theme.palette.background.paper,
                  backgroundColor: theme.palette.button.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.button.secondary,
                    borderColor: theme.palette.background.paper,
                    transform: "scale(0.95)",
                  },
                }}
              >
                Update
              </Button>

              <Button
                onClick={() => setOpenEditModal(false)}
                variant="outlined"
                sx={{
                  fontSize: theme.typography.responsiveFontSizes,
                  color: "white",
                  borderColor: "none",
                  opacity: "0.7",
                  backgroundColor: theme.palette.alert.warning,
                  "&:hover": {
                    borderColor: theme.palette.text.primary,
                    color: theme.palette.text.primary,
                    transform: "scale(0.95)",
                  },
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* modal  used for the switch button */}
        <Modal
          open={confirmDialog.open}
          onClose={handleCancelToggle}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: { xs: "80%", lg: "30%" },
              padding: 3,
              backgroundColor: theme.palette.background.paper,
              boxShadow: 24,
              borderRadius: 1,
              textAlign: "center",
            }}
          >
            <Typography id="confirm-dialog-title" variant="h6">
              Confirm Action
            </Typography>
            <Typography id="confirm-dialog-description" sx={{ mt: 2 }}>
              Are you sure you want to{" "}
              {confirmDialog.newStatus ? "activate" : "deactivate"} this coin?
            </Typography>
            <Box
              sx={{ mt: 4, display: "flex", justifyContent: "space-around" }}
            >
              <Button
                variant="contained"
                onClick={handleConfirmToggle}
                sx={{
                  color: theme.palette.text.primary,
                  borderColor: theme.palette.background.paper,
                  backgroundColor: theme.palette.button.primary,
                  "&:hover": {
                    backgroundColor: theme.palette.button.secondary,
                    borderColor: theme.palette.background.paper,
                    transform: "scale(0.95)",
                  },
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancelToggle}
                sx={{
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.alert.warning,
                  "&:hover": {
                    borderColor: theme.palette.text.primary,
                    color: theme.palette.text.primary,
                    transform: "scale(0.95)",
                  },
                }}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={snackbarState.message}
          key={`${vertical}${horizontal}`}
          sx={{ backgroundColor: snackbarState.backgroundColor }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Coins;
