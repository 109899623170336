import React, { useEffect, useState, useContext } from "react";
import "./home.scss";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import UserWidget from "../../components/widget/UserWidget";
import RevenueWidget from "../../components/widget/RevenueWidget";
import AssetsWidget from "../../components/widget/AssetsWidget";
import BalanceWidget from "../../components/widget/BalanceWidget";
import TotalTransactions from "../../components/widget/TotalTransactions";
import { Box, Grid, ThemeProvider, useTheme } from "@mui/material";
import { handleApiError } from "../../constants/helpers";
import { AuthContext } from "../../Auth/AuthContext";
import apiService from "../../services/apiServiceFetchers";
import Transactions from "./Transactions";

const Home = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await apiService.getTransactions(currentUser.token);
        setTransactions(response.transactions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        handleApiError(error, setOpenSnackbar, setSnackbarMessage);
      }
    };

    fetchTransactions();
  }, [currentUser.token]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


  return (
    <ThemeProvider theme={theme} sx={{ display: "flex", overflowX: "hidden" }}>
      {/* Widgets */}
      <Box  marginTop={1}>
        <Grid container spacing={3} className="row">
          <Grid item className="widget-container">
            <UserWidget />
          </Grid>
          <Grid item className="widget-container">
            <AssetsWidget />
          </Grid>
          <Grid item className="widget-container">
            <RevenueWidget />
          </Grid>
          <Grid item className="widget-container">
            <TotalTransactions />
          </Grid>
        </Grid>

        {/* Charts */}
        <Grid container spacing={4} className="row">
          <Grid item xs={12} sm={12} md={12} lg={6} className="chart-container">
            <Featured />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6}  className="chart-container">
            <Chart title="Last 6 Months Revenue" aspect={2 / 1} />
          </Grid>
        </Grid>

        {/* List Container */}
        <Grid container spacing={4} className="row">
          <Grid item className="balance-widget-container">
            <BalanceWidget />
          </Grid>
          <Grid item className="transactions-container">
            <Transactions
              transactions={transactions.slice(0, 5)}
              loading={loading}
              openSnackbar={openSnackbar}
              snackbarMessage={snackbarMessage}
              handleSnackbarClose={handleSnackbarClose}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Home;
