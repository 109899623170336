import React from "react";
import Datatable from "../../components/datatable/Datatable";
import { Box, Card } from "@mui/material";

const List = () => {
  return (
    <Card>
     
      <Box>
        {/* Pass the filter to Datatable */}
        <Datatable  />
      </Box>
    </Card>
  );
};

export default List;
