import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_GOCHAPAA_API_ENDPOINT;

const apiService = {
  loginUser: async (email, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, {
        email,
        password,
      });
      const { user, token, refreshToken } = response.data;
      return { user, token, refreshToken };
    } catch (error) {
      throw error;
    }
  },

  refreshToken: async (refreshToken) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/refresh-token`, {
        refreshToken,
      });
      const { token, refreshToken: newRefreshToken, user } = response.data;
      return { token, refreshToken: newRefreshToken, user };
    } catch (error) {
      throw error;
    }
  },
  //TO APPROVE USER KYC
  approveKyc: async (id, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/users/approve-kyc`,
        { userId: id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO REJECT A USER KYC
  rejectKyc: async (userId, message, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/users/reject-kyc`,
        {
          userId: userId,
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // TO CREATE A FAQS
  createFaq: async (faq, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/faqs/create`,
        faq,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO CREATE OWNER WALLLET
  createOwnerWallet: async (coinId, address, privateKey, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/wallets/owner`,
        { coinId, address, privateKey },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  // TO CREATE A COIN
  createCoin: async (token, coinData) => {
    try {
      
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/coinList/create`,
        coinData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      return response.data;
    } catch (error) {
      throw error;
    }
  },  
  // TO  CREATE A WITHDRAWAL REQUEST
  createWithdrawalRequest: async (withdrawalRequestData, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/v1/withdrawal-request/create`,
        withdrawalRequestData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  // TO CREATE Add PROFILE PICTURE
  addProfilePicture: async (profilePicture, token) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/me/profilePicture`,
        profilePicture,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default apiService;
