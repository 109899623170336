import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Snackbar,
  Paper,
  Box,
  useTheme,
  InputBase,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { ThemeProvider } from "@emotion/react";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import LoadingData from "../Loaders/Loading";
import { fetchCoinsOnly } from "../../redux/actions/coinsActions";

// Custom styled components
const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.alert.disabled,
  margin: theme.spacing(2),
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const SearchIconWrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: "40px",
  flex: 1,
  "& input": {
    padding: "10px 12px",
    width: "100%",
  },
}));

const ClearIconWrapper = styled(IconButton)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// Styled component for responsive table container
const TableContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  overflowX: "auto", // ✅ Enable horizontal scrolling
  display: "flex", // ✅ Ensures DataGrid doesn't shrink
  "& .MuiDataGrid-root": {
    border: "none",
    minWidth: 900, // ✅ Forces horizontal scrolling if screen is smaller
    width: "max-content", // ✅ Ensures columns don't get squished
  },
  "& .MuiDataGrid-cell": {
    borderBottom: `1px solid ${theme.palette.divider}`,
    fontSize: "0.9rem",
  },
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: theme.palette.background.default,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: theme.palette.background.paper,
  },

  // 📌 Responsive Styling for Small Screens
  [theme.breakpoints.down("sm")]: {
    "& .MuiDataGrid-root": {
      minWidth: "100%",
    },
    "& .MuiDataGrid-cell, & .MuiDataGrid-columnHeaders": {
      fontSize: "0.8rem",
      padding: "6px",
    },
  },
}));



function CustomToolbar({ searchText, setSearchText }) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
            >
              <ClearIcon />
            </ClearIconWrapper>
          )}
        </Search>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButtonBase-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-label": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <GridToolbar />
        </GridToolbarContainer>
      </Box>
      <Divider />
    </ThemeProvider>
  );
}

const formatDate = (date) => {
  if (!date) return "";

  const parsedDate = new Date(date);
  if (isNaN(parsedDate.getTime())) return "";

  return parsedDate.toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

const RevenueTable = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const coins = useSelector((state) => state.coins.allCoins || []);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { userId } = useParams();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiServiceFetchers.getRevenue(currentUser.token);
        const revenues = response.transactions || [];
        // Ensure each item has a unique id for DataGrid
        const revenuesWithIds = revenues.map((item) => ({
          ...item,
          id:
            item.id ||
            item.transaction_id ||
            Math.random().toString(36).substr(2, 9),
        }));
        setData(revenuesWithIds);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Failed to fetch revenues data.");
      }
    };
    fetchData();
  }, [currentUser.token, userId]);

  useEffect(() => {
    if (currentUser.token) {
      dispatch(fetchCoinsOnly(currentUser.token));
    }
  }, [dispatch, currentUser.token]);

  const filterData = (data, searchText) => {
    if (!data) return [];

    return data.filter((revenue) => {
      const matchesSearch =
        (revenue.transaction_kind &&
          revenue.transaction_kind
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (revenue.transaction_type &&
          revenue.transaction_type
            .toLowerCase()
            .includes(searchText.toLowerCase())) ||
        (revenue.id &&
          revenue.id.toLowerCase().includes(searchText.toLowerCase()));

      // Exclude deposits where transaction_charge_amount is 0
      const isDepositWithZeroFee =
        revenue.transaction_type === "deposit" &&
        parseFloat(revenue.transaction_charge_amount) === 0;

      return matchesSearch && !isDepositWithZeroFee;
    });
  };

  const filteredData = filterData(data, searchText);

  const columns = [
    {
      field: "id",
      headerName: "Transaction ID",
      width: 120,
      renderCell: (params) => {
        const truncatedId = params.value.split("-")[0].toUpperCase();
        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {truncatedId}
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Tooltip title={formatDate(params.value)} arrow>
          <span>{formatDate(params.value)}</span>
        </Tooltip>
      ),
    },
    {
      field: "transaction_kind",
      headerName: "COIN",
      flex: 1,
      minWidth: 50,
      renderCell: (params) => params.value?.toString().toUpperCase(),
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "transaction_charge_type",
      headerName: "Charge Type",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "transaction_charge_amount",
      headerName: "Fee Amount",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const amount = parseFloat(params.value);

        // Check if it's a deposit transaction and fee is 0, then hide it
        if (params.row.transaction_type === "deposit" && amount === 0) {
          return null; // Do not render anything
        }

        if (isNaN(amount) || amount === undefined || amount === null) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {"-"}
            </Box>
          );
        }

        const isCrypto = params.row.transaction_kind === "mpesa";
        const formattedAmount = isCrypto
          ? amount.toFixed(2)
          : amount.toFixed(8);

        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formattedAmount}
          </Box>
        );
      },
    },

    {
      field: "pay_coin_amount",
      headerName: "Transaction Amount",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        if (!params.value) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {""}
            </Box>
          );
        }

        const amount = parseFloat(params.value);

        if (isNaN(amount) || !isFinite(amount)) {
          return (
            <Box
              p={1}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "red",
              }}
            >
              Error
            </Box>
          );
        }

        // Check if transaction is M-Pesa (KES)
        const isMpesa =
          params.row.transaction_kind?.trim().toLowerCase() === "mpesa";

        // Find the matching coin from the Redux store
        const matchingCoin = coins.find((coin) => {
          const coinId = coin.coin_id?.trim().toLowerCase();
          const transactionKind = params.row.transaction_kind
            ?.trim()
            .toLowerCase();
          return coinId === transactionKind;
        });

        // Set decimal points based on matched coin or default values
        const minDecimals = isMpesa
          ? 2
          : matchingCoin?.minimum_decimal_point ?? 2;
        const maxDecimals = isMpesa
          ? 2
          : matchingCoin?.maximum_decimal_point ?? 8;

        // Format amount with the correct decimal places
        const formattedAmount = amount.toLocaleString("en-US", {
          minimumFractionDigits: minDecimals,
          maximumFractionDigits: maxDecimals,
        });

        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {formattedAmount}
          </Box>
        );
      },
    },

    {
      field: "transaction_status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <Box
          p={1}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            borderRadius: "4px",
            padding: "3px",
            opacity: "0.7",
            width: "100px",
            backgroundColor:
              params.row.transaction_status === "completed"
                ? "green"
                : params.row.transaction_status === "pending"
                ? theme.palette.alert.disabled
                : params.row.transaction_status === "rejected"
                ? "red"
                : "green",
          }}
        >
          {params.row.transaction_status}
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
          mt: 2,
        }}
      >
        {loading ? (
          <LoadingData />
        ) : (
          <TableContainer>
            <DataGrid
              rows={filteredData}
              columns={columns}
              disableSelectionOnClick
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              checkboxSelection={false}
              disableColumnMenu={false}
              autoHeight
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  searchText,
                  setSearchText,
                },
              }}
              pagination
              paginationMode="client"
              page={page}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize);
                setPage(0);
              }}
              sx={{
                "& .MuiDataGrid-virtualScroller": {
                  overflowX: "auto",
                },
                "& .MuiDataGrid-main": {
                  overflow: "auto",
                },
                // Force DataGrid to show scrollbar always for better UX
                "&, & .MuiDataGrid-root, & .MuiDataGrid-virtualScroller": {
                  overflowX: "auto !important",
                },
                // Ensure column headers don't wrap
                "& .MuiDataGrid-columnHeaders": {
                  whiteSpace: "nowrap",
                },
                // Style for cells to maintain consistent width
                "& .MuiDataGrid-cell": {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
          </TableContainer>
        )}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Paper>
    </Box>
  );
};

export default RevenueTable;
