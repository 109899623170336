import React, {
  useEffect,
  useCallback,
  useState,
  useContext,
  useRef,
} from "react";
import { Box, Typography, useTheme, Stack } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { AuthContext } from "../../Auth/AuthContext";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import Chart from "chart.js/auto";
import "react-circular-progressbar/dist/styles.css";
import "./featured.scss";
import { SkeletonShowCircleChartsCards } from "../Loaders/Skeleton";
import {
  getRandomColor,
  formatNumberWithCommas,
} from "../../constants/helpers";

const Featured = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const [coinData, setCoinData] = useState([]);
  const [completeBalanceData, setCompleteBalanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        // Fetch coin data
        const coinResponse = await apiServiceFetchers.getAllCoins(
          currentUser.token
        );
        if (coinResponse && coinResponse.coins) {
          setCoinData(coinResponse.coins);
        }

        // Fetch completed transactions
        const transactionResponse =
          await apiServiceFetchers.getcompletedTransactionsEachAsset(
            currentUser.token
          );

        setCompleteBalanceData(
          Object.entries(transactionResponse).map(([coinId, totalAmount]) => ({
            coinId,
            totalAmount,
          }))
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchChartData();
  }, [currentUser.token]);

  // Function to convert balance to USD
  // Memoize the convertToUSD function using useCallback
  const convertToUSD = useCallback(
    (amount, coinId) => {
      const coin = coinData.find((coin) => coin.coin_id === coinId);

      if (!coin) {
        return "N/A";
      }

      if (!coin.price || isNaN(parseFloat(coin.price))) {
        return "N/A";
      }

      const conversionRate = parseFloat(coin.price);
      const convertedBalance = parseFloat(amount) * conversionRate;
      if (isNaN(convertedBalance)) {
        return "Error: Invalid conversion";
      }

      return convertedBalance.toFixed(2);
    },
    [coinData]
  );

  useEffect(() => {
    const canvas = chartRef.current;

    if (canvas && !loading) {
      const labels = [];
      const data = [];

      completeBalanceData.forEach(({ coinId, totalAmount }) => {
        const amount = parseFloat(totalAmount);

        if (!isNaN(amount) && amount > 0) {
          // ✅ Exclude coins with balance 0
          let amountInUSD = amount; // Default without conversion

          // Convert only if necessary
          if (convertToUSD) {
            const convertedAmount = parseFloat(convertToUSD(amount, coinId));
            if (!isNaN(convertedAmount)) {
              amountInUSD = convertedAmount;
            }
          }

          labels.push(coinId);
          data.push(amountInUSD);
        }
      });

      if (labels.length === 0) return; // ✅ Prevent empty chart rendering

      // Define theme colors
      const themeColors = [
        theme.palette.alert.disabled,
        theme.palette.background.paper2,
        theme.palette.alert.warning,
        theme.palette.alert.success,
      ];

      // Generate random colors
      const randomColors = Array.from(
        { length: labels.length }, // ✅ Use filtered labels length
        getRandomColor
      );

      // Combine theme colors and random colors
      const backgroundColor = [
        ...themeColors.slice(0, Math.min(4, labels.length)),
        ...randomColors.slice(0, Math.max(0, labels.length - 4)),
      ].slice(0, labels.length);

      const chartInstance = new Chart(canvas, {
        type: "doughnut",
        data: {
          labels: labels, // ✅ Filtered Coin IDs
          datasets: [
            {
              data: data, // ✅ Filtered amounts
              backgroundColor: backgroundColor,
            },
          ],
        },
        options: {
          cutout: "75%",
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  let label = context.label || "";
                  if (label) {
                    const value = context.raw || 0;
                    const total = context.chart.data.datasets[0].data.reduce(
                      (acc, val) => acc + val,
                      0
                    );
                    const percentage = Math.round((value / total) * 100);
                    label += `: ${percentage}%`;
                  }
                  return label;
                },
              },
            },
          },
        },
      });

      return () => {
        chartInstance.destroy();
      };
    }
  }, [theme.palette, loading, completeBalanceData, coinData, convertToUSD]);

  if (loading) {
    return <SkeletonShowCircleChartsCards theme={theme} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: 4,
          height: 500,
          p: 0.1,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 30, md: 30, lg: 20 },
              fontWeight: 600,
              marginTop: 2,
              textAlign: "center",
              marginBottom: 2,
            }}
          >
            Transaction Volume
          </Typography>
        </Box>
        <Stack spacing={2} direction={{ xs: "column", md: "row" }}>
          {/* the donut column */}
          <Box
            sx={{
              flex: 1,
              // padding: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                // marginTop: 4,
                display: "flex",
                alignItems: "flex-start",
                marginLeft: 10,
              }}
            >
              <canvas ref={chartRef}></canvas>
            </Box>{" "}
          </Box>
          {/* COLUMN 2 */}
          <Box
            sx={{
              flex: 1,
              padding: 1,
              height: 450,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: 24, md: 20, lg: 16 },
                fontWeight: 600,
                textAlign: "center",
                marginBottom: 2,
              }}
            >
              Sum Of Completed Transactions
            </Typography>

            {/* SCROLLABLE LIST CONTAINER */}
            <Box
              sx={{
                width: "100%",
                maxHeight: 350, // Adjust based on your preference
                overflowY: "auto", // Enables vertical scrolling
                paddingRight: 1, // Prevents text from getting cut off
              }}
            >
              {completeBalanceData
                .filter(({ totalAmount }) => Number(totalAmount) > 0.00000001) // Ignore near-zero balances
                .map(({ coinId, totalAmount }) => {
                  // Ensure correct coin matching
                  const matchingCoin = coinData.find(
                    (coin) => coin.coin_id === coinId
                  );

                  if (!matchingCoin) return null; // Skip if coin not found

                  const coinLabel = matchingCoin.coin_label;
                  const displayedAmount = totalAmount ?? 0;

                  // Get correct decimal places
                  const minDecimals = matchingCoin.minimum_decimal_point ?? 2;
                  const maxDecimals = matchingCoin.maximum_decimal_point ?? 8;

                  // Properly format numbers based on decimals
                  const formattedAmount = displayedAmount.toLocaleString(
                    "en-US",
                    {
                      minimumFractionDigits: minDecimals,
                      maximumFractionDigits: maxDecimals,
                    }
                  );

                  return (
                    <Box key={coinId} sx={{ width: "100%", mb: 1 }}>
                      <Box
                        sx={{
                          pl: 4,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 24, md: 18, lg: 16 },
                            fontWeight: "bold",
                            color: theme.palette.text.main,
                          }}
                        >
                          {coinLabel}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 24, md: 18, lg: 16 },
                            color: theme.palette.text.secondary,
                            pl: 6,
                          }}
                        >
                          {formattedAmount}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: theme.palette.text.secondary,
                          fontSize: { xs: 22, md: 18, lg: 16 },
                          pl: 10,
                        }}
                      >
                        {formatNumberWithCommas(
                          Number(convertToUSD(displayedAmount, coinId))
                        )}{" "}
                        USD
                      </Typography>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};

export default Featured;
