import React from "react";
import { Tooltip } from "@mui/material";
import { styled, useTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Navbar from "../navbar.js/Navbar";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HomeIcon from "@mui/icons-material/Home";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
// import WalletIcon from '@mui/icons-material/Wallet';
import Footer from "../../Pages/Footer/Footer";

const menuItems = [
  { text: "Home", icon: <HomeIcon />, link: "/" },
  { text: "Users", icon: <PersonOutlineIcon />, link: "/users" },
  // { text: "Wallets", icon: <WalletIcon />, link: "/wallets" },
  {
    text: "Transactions",
    icon: <PaymentsOutlinedIcon />,
    link: "/transactionpage",
  },
  {
    text: "Revenues",
    icon: <AccountBalanceWalletIcon />,
    link: "/revenue",
  },
  { text: "Coins", icon: <PaidOutlinedIcon />, link: "/coins" },
  {
    text: "Notifications",
    icon: <NotificationsNoneIcon />,
    link: "/notifications",
  },
  { text: "Faqs", icon: <HelpCenterOutlinedIcon />, link: "/faqs" },
];

const lastMenuItem = {
  text: "Logout",
  icon: <ExitToAppIcon />,
  link: "/logout",
};

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("Box")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Layout({ toggleMode, prefersDarkMode, children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", overflowX: "hidden" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar sx={{ backgroundColor: theme.palette.background.paper }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon sx={{ color: theme.palette.text.primary }} />
            </IconButton>

            <Navbar toggleMode={toggleMode} />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box sx={{ flexGrow: 1 }}>
            <List>
              {menuItems.map((item, index) => (
                <Tooltip key={index} title={item.text} placement="right" arrow>
                  <ListItem
                    button
                    component="a"
                    href={item.link}
                    disablePadding
                    sx={{ display: "block", marginTop: 2 }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                        "&:hover": {
                          backgroundColor: theme.palette.background.paper1,
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </Box>
          <Box sx={{ mt: "auto" }}>
            <Tooltip title={lastMenuItem.text} placement="right" arrow>
              <ListItem
                button
                component="a"
                href={lastMenuItem.link}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    backgroundColor: theme.palette.background.paper2,
                    px: 2.5,
                    borderTopRightRadius: 40,
                    borderBottomRightRadius: 40,
                    marginRight: 1,
                    color: "white",
                    marginBottom: 15,
                    "&:hover": {
                      backgroundColor: theme.palette.background.paper2,
                      transform: "scale(0.95)",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color: "white",
                      "&:hover": {
                        backgroundColor: theme.palette.action.selected,
                      },
                    }}
                  >
                    {lastMenuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={lastMenuItem.text}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            marginTop: 2,
            flexGrow: 1,
            p: 2,
          }}
        >
          <DrawerHeader />
          {children}
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Layout;
