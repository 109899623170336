import React, { useState, useEffect, useContext } from "react";
import {
  Snackbar,
  Button,
  TableCell,
  Box,
  useTheme,
  InputBase,
  Grid,
  Divider,
  Card,
  CardContent,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams, useLocation } from "react-router-dom";
import { AuthContext } from "../../Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridToolbarContainer, GridToolbar } from "@mui/x-data-grid";
import { tableCellClasses } from "@mui/material/TableCell";
import { ThemeProvider } from "@emotion/react";
import apiServiceFetchers from "../../services/apiServiceFetchers";
import LoadingData from "../Loaders/Loading";

// Custom styled components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: theme.typography.responsiveFontSizes,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  padding: 6,
  fontSize: theme.typography.responsiveFontSizes,
}));

const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  borderRadius: "4px",
  border: "1px solid",
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.alert.disabled,
  margin: theme.spacing(2),
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    maxWidth: "none",
  },
}));

const SearchIconWrapper = styled(Box)({
  padding: "10px",
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  marginLeft: "40px",
  flex: 1,
  "& input": {
    padding: "10px 12px",
    width: "100%",
  },
}));

const ClearIconWrapper = styled(IconButton)({
  padding: "10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function CustomToolbar({ searchText, setSearchText }) {
  const theme = useTheme();

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {searchText && (
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
            >
              <ClearIcon />
            </ClearIconWrapper>
          )}
        </Search>
        <GridToolbarContainer
          sx={{
            display: "flex",
            alignItems: "center",
            color: theme.palette.text.primary,
            "& .MuiTypography-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButtonBase-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-root": {
              color: theme.palette.text.primary,
            },
            "& .MuiButton-label": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputBase-input": {
              color: theme.palette.text.primary,
            },
          }}
        >
          <GridToolbar />
        </GridToolbarContainer>
      </Box>
      <Divider />
    </ThemeProvider>
  );
}

const Datatable = () => {
  const theme = useTheme();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const { userId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = queryParams.get("filter");

  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await apiServiceFetchers.getUsers(currentUser.token);
        setData(users);
      } catch (error) {
        setOpenSnackbar(true);
        setSnackbarMessage("Failed to fetch users data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentUser.token, userId]);

  // Filter users based on verification status
  const filteredUsers =
    filter === "verified"
      ? data.filter((user) => user.verificationStatus === "verified")
      : filter === "pending"
      ? data.filter((user) => user.verificationStatus === "pending")
      : filter === "rejected"
      ? data.filter((user) => user.verificationStatus === "rejected")
      : filter === "unverified"
      ? data.filter(
          (user) =>
            !["verified", "pending", "rejected"].includes(
              user.verificationStatus
            )
        )
      : data;

  // User statistics
  const totalUsers = data.length;
  
  const pendingUsers = data.filter(
    (user) => user.verificationStatus === "pending"
  ).length;
  const activeUsers = data.filter(
    (user) => user.verificationStatus === "verified"
  ).length;
  const rejectedUsers = data.filter(
    (user) => user.verificationStatus === "rejected"
  ).length;
  const unverifiedUsers = data.filter(
    (user) =>
      !["verified", "pending", "rejected"].includes(user.verificationStatus)
  ).length;

  // TO FILTER DATA TO  SEARCH USER BY CERTAIN CRITERIA
  const normalizedSearchText = searchText.toLowerCase().trim();

  // Step 1: Filter users based on verification status
  const statusFilteredUsers =
    filter === "verified"
      ? data.filter((user) => user.verificationStatus === "verified")
      : filter === "pending"
      ? data.filter((user) => user.verificationStatus === "pending")
      : filter === "rejected"
      ? data.filter((user) => user.verificationStatus === "rejected")
      : filter === "unverified"
      ? data.filter(
          (user) =>
            !["verified", "pending", "rejected"].includes(
              user.verificationStatus
            )
        )
      : data;

  // Step 2: Apply search filter
  const finalFilteredUsers = statusFilteredUsers.filter((user) => {
    const firstName = (user.firstName || "").toLowerCase();
    const lastName = (user.lastName || "").toLowerCase();
    const email = (user.email || "").toLowerCase();
    const phone = (user.phone || "").replace(/\D/g, ""); // Remove non-digit characters
    const localPhoneNumber = phone.startsWith("0")
      ? phone
      : "0" + phone.replace(/^\+?\d{1,3}/, "");

    return (
      firstName.includes(normalizedSearchText) ||
      lastName.includes(normalizedSearchText) ||
      email.includes(normalizedSearchText) ||
      phone.includes(normalizedSearchText) ||
      localPhoneNumber.includes(normalizedSearchText)
    );
  });

  // Step 3: Sort priority (Exact matches first)
  const sortedFilteredUsers = finalFilteredUsers.sort((a, b) => {
    const aMatch =
      a.firstName.toLowerCase() === normalizedSearchText ||
      a.lastName.toLowerCase() === normalizedSearchText ||
      a.email.toLowerCase() === normalizedSearchText ||
      a.phone.replace(/\D/g, "") === normalizedSearchText;

    const bMatch =
      b.firstName.toLowerCase() === normalizedSearchText ||
      b.lastName.toLowerCase() === normalizedSearchText ||
      b.email.toLowerCase() === normalizedSearchText ||
      b.phone.replace(/\D/g, "") === normalizedSearchText;

    return bMatch - aMatch; // Prioritize exact matches
  });

  // Debugging

  const columns = [
    { field: "firstName", headerName: "First Name", flex: 1, minWidth: 150 },
    { field: "lastName", headerName: "Last Name", flex: 1, minWidth: 150 },
    { field: "email", headerName: "Email", flex: 1, minWidth: 200 },
    { field: "phone", headerName: "Phone", flex: 1, minWidth: 150 },
    {
      field: "verificationStatus",
      headerName: "Verification Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        let backgroundColor;
        let statusText;

        switch (params.row.verificationStatus) {
          case "verified":
            backgroundColor = "green";
            statusText = "Verified";
            break;
          case "pending":
            backgroundColor = "orange";
            statusText = "Pending";
            break;
          case "rejected":
            backgroundColor = "red";
            statusText = "rejected";
            break;
          default:
            backgroundColor = "grey";
            statusText = "Unverified";
        }

        return (
          <Box
            p={1}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              borderRadius: "4px",
              padding: "3px",
              opacity: "0.7",
              width: "100px",
              backgroundColor,
            }}
          >
            {statusText}
          </Box>
        );
      },
    },
    {
      field: "dateOfBirth",
      headerName: "Date of Birth",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <span className={`status ${params.value}`}>
          {params.value
            ? new Date(params.value).toLocaleDateString("en-GB")
            : "-"}
        </span>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <StyledTableCell>
          <StyledButton
            startIcon={<VisibilityIcon />}
            onClick={() => navigate(`/users/userdetails/${params.row.id}`)}
          >
            View User
          </StyledButton>
        </StyledTableCell>
      ),
    },
  ];

  const handleClearSearch = () => {
    setSearchText("");
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ p: 2 }}>
        {[
          {
            title: "Total Users",
            value: totalUsers,
            bgColor: "primary.main",
            filter: "all",
          },
          {
            title: "Pending Approval",
            value: pendingUsers,
            bgColor: "orange",
            filter: "pending",
          },
          {
            title: "Active Users",
            value: activeUsers,
            bgColor: "success.main",
            filter: "verified",
          },
          {
            title: "Rejected Users",
            value: rejectedUsers,
            bgColor: "error.main",
            filter: "rejected",
          },
          {
            title: "New Users (Unverified)",
            value: unverifiedUsers,
            bgColor: "grey",
            filter: "unverified",
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Card
              sx={{
                backgroundColor: item.bgColor,
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => navigate(`/users?filter=${item.filter}`)}
            >
              <CardContent>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="h5">
                  {loading ? <Typography>Loading...</Typography> : item.value}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ minHeight: "100vh", width: "100%", mt: 2 }}>
        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <LoadingData />
          </Box>
        ) : (
          <DataGrid
            rows={sortedFilteredUsers}
            columns={columns}
            autoHeight
            page={page}
            pageSize={pageSize}
            rowCount={totalUsers} // Use the total count from the API
            paginationMode="server"
            onPageChange={handleChangePage} // Directly use the function
            onPageSizeChange={handlePageSizeChange} // Directly use the function
            rowsPerPageOptions={[10, 25, 50]}
            components={{
              Toolbar: () => (
                <CustomToolbar
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
              ),
            }}
            componentsProps={{
              toolbar: { searchText, setSearchText },
            }}
            sx={{
              "& .MuiDataGrid-cell": {
                fontSize: theme.typography.responsiveFontSizes,
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontSize: theme.typography.responsiveFontSizes,
              },
              padding: "2%",
            }}
          />
        )}
        {/* Additional check for filteredData length */}
        {!loading && filteredUsers.length === 0 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h5" mb={2}>
              No User found
            </Typography>
            <ClearIconWrapper
              onMouseDown={(e) => e.preventDefault()}
              onClick={handleClearSearch}
              sx={{ marginBottom: 2 }}
            >
              <ClearIcon />
            </ClearIconWrapper>
          </Box>
        )}
      </Box>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </ThemeProvider>
  );
};

export default Datatable;
